<div class="container">
  <form class="left-info" [formGroup]="informationForm" *ngIf="informationForm">
    <div class="main-info">
      <span class="info-input-container">
        <span class="input-label w-132">
          Vendor/Mnf:
        </span>
        <input type="text" class="info-input" [disabled]="true" [value]="this.instrumentData?.data?.vendor?.name">
      </span>
      <span class="info-input-container">
        <span class="input-label">
          SKU:
        </span>
        <input type="text" class="info-input" [disabled]="true" [value]="this.instrumentData?.data?.sku">
      </span>
      <span class="info-input-container">
        <span class="input-label">
          Description:
        </span>
        <textarea rows="3" cols="23" class="info-input" [disabled]="true" [value]="this.instrumentData?.data?.name"></textarea>
      </span>
      <span class="info-input-container">
        <span class="input-label">
          Status:
        </span>
        <mat-select formControlName="status">
            <mat-option *ngFor="let status of statuses" [value]="status.value">
              {{ status.viewValue }}
            </mat-option>
          </mat-select>
      </span>
      <span class="info-input-container">
        <span class="input-label">
          Visibility:
        </span>
        <mat-select formControlName="is_public">
            <mat-option
              *ngFor="let statusVisibility of statusesVisibility"
              [value]="statusVisibility.value"
            >
              {{ statusVisibility.viewValue }}
            </mat-option>
          </mat-select>
      </span>
    </div>
<!--    <div class="steris-support">-->
<!--      <h3 class="text-header">STERIS support view</h3>-->
<!--      <span class="info-input-container">-->
<!--        <span class="input-label">-->
<!--          SPM_ID-->
<!--        </span>-->
<!--        <input type="text" class="info-input" formControlName="spm_id">-->
<!--      </span>-->
<!--      <span class="info-input-container">-->
<!--        <span class="input-label">-->
<!--          SPM_Global_ID-->
<!--        </span>-->
<!--        <input type="text" class="info-input" formControlName="global_id">-->
<!--      </span>-->

<!--    </div>-->
<!--    <div class="layerjot-support">-->
<!--      <h3 class="text-header">LayerJot Admin view</h3>-->
<!--      <span class="info-input-container">-->
<!--        <span class="input-label">-->
<!--          Team-->
<!--        </span>-->
<!--        <input type="text" class="info-input" formControlName="team">-->
<!--      </span>-->
<!--      <span class="info-input-container">-->
<!--        <span class="input-label mr-15">-->
<!--          LJUUID-->
<!--        </span>-->
<!--        <input type="text" class="info-input" formControlName="ljuuid">-->
<!--      </span>-->
<!--    </div>-->
  </form>
  <div class="multiple-drag-and-drop">
  <div class="right-photo">
    <span class="area-text">Main</span>
    <div
      cdkDropList
      id="approvedList"
      #approvedList="cdkDropList"
      class="drag-n-drop-photo"
      [cdkDropListData]="approved"
      [cdkDropListConnectedTo]="[waitingApprovalList, hiddenList]"
      (cdkDropListDropped)="drop($event)"
    >
    <div class="drag-photo" *ngFor="let photo of approved" cdkDrag [cdkDragData]="photo">
      <div class="drag-photo_wrap">
        <img src="{{photo.url}}" width="125" alt="" class="catalog-photo" matTooltip="{{photo?.date_created | date}}" matTooltipPosition="above">
      </div>
    </div>
      <div class="drag-photo" *ngIf="loadingFiles">
        <div class="drag-photo_wrap">
          <mat-spinner></mat-spinner>
        </div>
      </div>
  </div>
  </div>
  <div class="awaiting-approval-container right-photo">
      <span class="area-text">Images yet to be approved</span>
    <div cdkDropList
         id="waitingApprovalList"
         #waitingApprovalList="cdkDropList"
         class="drag-n-drop-photo"
         [cdkDropListData]="waitingApproval"
         [cdkDropListConnectedTo]="[approvedList, hiddenList]"
         (cdkDropListDropped)="drop($event)"
    >
      <div class="upload-area" *ngIf="!loadingFiles">
        <input type="file" multiple="multiple" (change)="getImages($event)" placeholder="upload" accept="image/*">
        <img src="/assets/images/add-photo.svg" alt="" class="upload-area-placeholder" *ngIf="!loadingCatalogImage">
        <mat-spinner *ngIf="loadingCatalogImage"></mat-spinner>
      </div>
      <div class="drag-photo with-btn" *ngFor="let photo of waitingApproval; let i = index" cdkDrag [cdkDragData]="photo">
        <div class="drag-photo_wrap">
          <img src="{{photo.url}}" alt="" width="125" class="catalog-photo" matTooltip="{{photo?.date_created | date}}" matTooltipPosition="above" >
        </div>
        <div class="drag-photo_btn">
          <button mat-icon-button matTooltip="Delete intrument" (click)="deleteFile(photo, i)" [disabled]="loadingDeleteAwaitingBtn[i]" color="warn">
            <mat-icon aria-label="Delete">delete</mat-icon>
          </button>
        </div>
      </div>
      <div class="drag-photo" *ngIf="loadingFiles">
        <div class="drag-photo_wrap">
          <mat-spinner></mat-spinner>
        </div>
      </div>
    </div>
  </div>
    <div class="hidden-container right-photo">
      <span class="area-text">Hidden images</span>
      <div cdkDropList
           id="hiddenList"
           #hiddenList="cdkDropList"
           class="drag-n-drop-photo"
           [cdkDropListData]="hidden"
           [cdkDropListConnectedTo]="[approvedList, waitingApprovalList]"
           (cdkDropListDropped)="drop($event)"
      >
        <div class="drag-photo with-btn" *ngFor="let photo of hidden; let i = index" cdkDrag [cdkDragData]="photo">
          <div class="drag-photo_wrap">
            <img src="{{photo.url}}" alt="" width="125" class="catalog-photo hidden-photo" matTooltip="{{photo?.date_created | date}}" matTooltipPosition="above">
          </div>
          <div class="drag-photo_btn">
            <button mat-icon-button matTooltip="Delete intrument" (click)="deleteFileHidden(photo, i)" [disabled]="loadingDeleteHiddenBtn[i]" color="warn">
              <mat-icon aria-label="Delete">delete</mat-icon>
            </button>
          </div>
        </div>
        <div class="drag-photo" *ngIf="loadingFiles">
          <div class="drag-photo_wrap">
            <mat-spinner></mat-spinner>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
<div class="actions">
  <button mat-button class="update-instrument" (click)="submitForm()">
    Update instrument info
  </button>
</div>
