import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GeneralHelpers } from '../../helpers/general/general.helper';
import { API_BASE_V2, CATALOG } from '../../constants/general.constants';
import { Observable } from 'rxjs';
import { InstrumentCapture, InstrumentCaptureResponse } from '../../interfaces/instrument-capture.interface';

const HUB = 'hub';
const CAPTURE = 'capture';
const INSTRUMENT = 'instrument';

@Injectable({
  providedIn: 'root'
})
export class InstrumentCaptureService {

  constructor(private http: HttpClient) { }

  // ────────────────────────────────────────────────────────────────────────────────
  // Create

  create(data: { data: InstrumentCapture }): Observable<Object> {
    (data as any) = GeneralHelpers.toMultipartFormData(data.data)
    return this.http
      .post<InstrumentCapture>(`${API_BASE_V2}${HUB}/${CATALOG}/${CAPTURE}/${INSTRUMENT}/`, data);
  }

  // ────────────────────────────────────────────────────────────────────────────────
  // Read

  get(
    offset: number = 0,
    limit: number = 25,
    ordering: string = '',
    search: string = '',
    is_pagination: boolean = false,
    status: string = '',
  ): Observable<InstrumentCapture[]> {
    const options = {
      params: GeneralHelpers.getParams({ offset, limit, ordering, search, is_pagination, status }),
    };

    return this.http.get<InstrumentCapture[]>(
      `${API_BASE_V2}${HUB}/${CATALOG}/${CAPTURE}/${INSTRUMENT}/`, options
    );
  }

  // ─────────────────────────────────────────────────────────────────────────────
  // Get by id
  getById(
    id: string
  ): Observable<InstrumentCapture> {
    return this.http.get<InstrumentCapture>(
      `${API_BASE_V2}${HUB}/${CATALOG}/${CAPTURE}/${INSTRUMENT}/${id}/`
    );
  }

  // ────────────────────────────────────────────────────────────────────────────────
  // Update

  update(id: number, data: { data: InstrumentCapture }): Observable<Object> {
    (data as any) = GeneralHelpers.toMultipartFormData(data.data)
    return this.http
      .put<InstrumentCapture>(`${API_BASE_V2}${HUB}/${CATALOG}/${CAPTURE}/${INSTRUMENT}/${id}/`, data);
  }

  // ────────────────────────────────────────────────────────────────────────────────
  // Actions

  doVisionAction(id: number, body: { file_ids: number[], action: number }) {
    (body as any) = GeneralHelpers.toMultipartFormData(body);
    return this.http.put(
      `${API_BASE_V2}${HUB}/${CATALOG}/${CAPTURE}/${INSTRUMENT}/${id}/`, body
    );
  }

  // ────────────────────────────────────────────────────────────────────────────────
  // Delete
  delete(id: string, body: { is_delete_permanently: boolean }) {
    (body as any) = GeneralHelpers.toMultipartFormData(body);
    return this.http.delete(
      `${API_BASE_V2}${HUB}/${CATALOG}/${CAPTURE}/${INSTRUMENT}/${id}/`, { body }
    );
  }

}
