import {Component, EventEmitter, Inject, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-instrument-edit',
  templateUrl: './instrument-edit.component.html',
  styleUrls: ['./instrument-edit.component.scss']
})
export class InstrumentEditComponent {

  @Output() updateInstruments = new EventEmitter<any>();

  constructor(
    public dialogRef: MatDialogRef<InstrumentEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
  }


  onCancelClick(): void {
    this.dialogRef.close();
    window.location.reload();
  }

}
