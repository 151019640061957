<div class="header">
  <h2 mat-dialog-title>{{data?.title}}</h2>
  <button mat-icon-button [mat-dialog-close]="false" *ngIf="data.hasClose">
    <mat-icon>close</mat-icon>
  </button>
</div>

<div mat-dialog-content>
  <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="2%">
    <div fxFlex="67" class="message">
      <p [innerHtml]="data.message"></p>
    </div>
    <div fxFlex="31">
      <div class="file-upload"
        [ngClass]="{'file-upload-fail': data.icon === 'file-upload-fail', 'file-upload-success': data.icon === 'file-upload-success'}">

      </div>
    </div>
  </div>
</div>

<div mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between center" class="mb-0 mt-3">
  <button mat-button class="tray-button blue" [mat-dialog-close]="data" *ngIf="data?.confirmCaption">
    {{data?.confirmCaption}}
  </button>
  <button mat-button class="tray-button transparent" [mat-dialog-close]="false" *ngIf="data?.cancelCaption">
    {{data?.cancelCaption}}
  </button>
</div>