import {AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {INSTRUMENT_STATUSES, INSTRUMENTS_VISIBILITY} from "../../../constants/general.constants";
import {MatDialog} from "@angular/material/dialog";
import {InstrumentEditComponent} from "../../instrument-edit/instrument-edit.component";
import {environment} from '../../../../../environments/environment';
import {
  BehaviorSubject,
  debounceTime,
  distinctUntilChanged,
  finalize,
  fromEvent,
  map,
  take
} from "rxjs";
import {InstrumentsService} from "../../../services/instruments/instruments.service";
import {FormControl} from "@angular/forms";
import {ActivatedRoute} from "@angular/router";
import {MatSort, Sort} from "@angular/material/sort";
import {MatSelectChange} from "@angular/material/select";

@Component({
  selector: 'app-instrument-table',
  templateUrl: './instrument-table.component.html',
  styleUrls: ['./instrument-table.component.scss']
})
export class InstrumentTableComponent implements OnChanges, AfterViewInit, OnInit {

  public updateInstrumentDialogRef: any;
  public visibilities = INSTRUMENTS_VISIBILITY;
  public instrumentStatuses = INSTRUMENT_STATUSES;
  public count$ = new BehaviorSubject(0);
  @Input() tableValue: any;
  @Input() tableDataSource = [];
  @Input() loading!: boolean
  @Output() getDataSource: EventEmitter<any> = new EventEmitter();
  @Output() sorting: EventEmitter<any> = new EventEmitter();
  @ViewChild('search') search!: ElementRef;
  @ViewChild(MatSort) sort!: MatSort;
  searchControl = new FormControl();
  teamName!: string;


  constructor(
    public dialog: MatDialog,
    private instrumentsService: InstrumentsService,
    private route: ActivatedRoute,
  ) { }
  displayedColumns1: string[] = ['name', 'vendor__name', 'sku', 'actions'];
  // displayedColumns1: string[] = ['name', 'vendor__name', 'sku', 'actions', 'ljAdmin'];
  displayedColumns2: string[] = ['name', 'vendor__name', 'sku','status', 'visibility', 'date', 'actions'];
  displayedColumns3: string[] = ['photo','organization_medical_instrument__medical_instrument__name', 'organization_medical_instrument__medical_instrument__vendor__name', 'organization_medical_instrument__medical_instrument__sku','status', 'date_created', 'actions'];
  displayedColumns4: string[] = ['name','vendor__name', 'sku', 'status', 'date', 'actions'];
  dataSource: any;

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.teamName = params['teamName'];
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.tableDataSource) {
      this.loading = changes.loading.currentValue
      this.dataSource = new MatTableDataSource(changes.tableDataSource.currentValue)
      this.dataSource.sort = this.sort
    }
  }

  ngAfterViewInit(): void {
    if (this.search) {
      fromEvent(this.search.nativeElement, 'input')
        // @ts-ignore
        .pipe(map((event: Event) => (event.target as HTMLInputElement).value))
        .pipe(debounceTime(800))
        .pipe(distinctUntilChanged())
        .subscribe(data => this.searchInstruments(data));
    }

  }

  onScroll() {
    this.getDataSource.emit();
  }

  public openUpdateCatalog(data: any, tabIndex:number) {
    this.updateInstrumentDialogRef = this.dialog.open(InstrumentEditComponent, {
      width: '1500px',
      autoFocus: false,
      data: {
        data,
        tabIndex
      }
    });
    this.updateInstrumentDialogRef.componentInstance.updateInstruments.subscribe(() => {
      this.getDataSource.emit();
    })
  }

  deleteCatalogInstrument(data:any) {
    this.instrumentsService.delete(data.uuid).pipe(take(1)).subscribe()
  }

  approveInstrument(data: any) {
    const status: FormData = new FormData()
    status.append('status', '2')
    this.instrumentsService.updateInstrumentPhoto(status, data.uuid)
      .pipe(take(1))
      .subscribe((res:any) => {
        data.status = res.status
      })
  }

  searchInstruments(q:any) {
    let req = {
      offset:0,
      limit: 25,
      is_common_search: true,
      is_pagination: true,
      is_infinity_scroll: true,
      q:q
    }
      this.instrumentsService.getNewHubInstruments(req).pipe(
        distinctUntilChanged(),
      ).subscribe((res:any) => {
        this.dataSource = this.dataSource = new MatTableDataSource(res.results)
      })
  }

  statusMapping(data:number) {
    const status = this.instrumentStatuses.filter(statuses => statuses.value == data);
    return status[0].viewValue
  }

  acceptInstrument(element:any){
    const req ={
      instrument_uuid: element.uuid,
      uuid: element.uuid
    }
    element.is_approved = true;
    this.instrumentsService.approveInstrumentPhoto(req, { is_approved: true }).pipe(
      take(1),
      finalize(() => {
        this.dataSource = this.dataSource.filter((item:any) => item.uuid !== element.uuid)
      })
    ).subscribe()
  }

  changeVisibility(event: MatSelectChange, instrument:any) {
    const req = {
      is_public: event.value,
    }
    this.instrumentsService.modifyNewHubInstrument(req, instrument.uuid).pipe(
      take(1)
    ).subscribe()
  }

  sortPhotoData(sort: Sort){
    this.count$.pipe(take(1)).subscribe(res => {
      const req = {
        offset: res,
        limit: 25,
        is_common_search: false,
        is_pagination: true,
        is_approved: false,
        ordering: sort.active
      }
      this.instrumentsService.getNewHubPhotos(req).pipe(
        take(1),
      ).subscribe((res:any) => {
        this.dataSource = res.results
        this.count$.next(res.count)
      })
    })
  }

  sortInstrumentData(sort :Sort) {
    this.count$.pipe(take(1)).subscribe(res => {
      const req = {
        offset: res,
        limit: 25,
        status: '2',
        is_common_search: false,
        is_pagination: true,
        is_infinity_scroll: true,
        ordering: sort.active
      }
      this.instrumentsService.getNewHubInstruments(req).pipe(
        take(1),
        finalize(() => {
        })
      ).subscribe((res:any) => {
        this.dataSource = res.results
        this.count$.next(res.count)
      })
    })
    this.sorting.emit(sort)
  }

  deleteInstrument(element:any) {
    const req ={
      instrument_uuid: element.uuid,
      uuid: element.uuid
    }
    this.instrumentsService.deleteInstrumentPhoto(req).pipe(take(1)).subscribe()
  }

  addInstrument() {
    window.open(`${environment.addInstrumentRedirectUrl}`, '_blank')
  }
}
