import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InstrumentFormComponent } from './instrument-form.component';

// ────────────────────────────────────────────────────────────────────────────────
// Material
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';

// ────────────────────────────────────────────────────────────────────────────────
// Forms Module
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

// Grid module ────────────────────────────────────────────────────────────────────
import { FlexLayoutModule } from '@angular/flex-layout';

// ─────────────────────────────────────────────────────────────────────────────
// Custom progress
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { TranslateModule } from '@ngx-translate/core';
import { SwiperModule } from "swiper/angular";

@NgModule({
  imports: [
    CommonModule,

    // Material
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatCardModule,
    MatChipsModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    MatProgressBarModule,
    MatSelectModule,

    // Form
    ReactiveFormsModule,
    FormsModule,

    // Layout
    FlexLayoutModule,

    // Custom scroll
    PerfectScrollbarModule,
    SwiperModule,
    // Translations
    TranslateModule.forChild(),
  ],
  declarations: [InstrumentFormComponent],
  exports: [InstrumentFormComponent]
})
export class InstrumentFormModule { }
