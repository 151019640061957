<div class="container">
  <div class="org-container">
    <span class="org-header">Captures per org</span>
    <span class="org-name">LayerJot <span class="captures-number">(24)</span></span>
  </div>
  <div class="drag-container">
    <div class="drag-header">Approved <span class="captures-number">(18)</span></div>
    <div class="drag-body"
         cdkDropList
         #approved="cdkDropList"
         [cdkDropListData]="approvedList"
         [cdkDropListConnectedTo]="[awaiting,rejected,deleted]"
         (cdkDropListDropped)="drop($event)"
    >

    </div>
  </div>
  <div class="drag-container">
    <div class="drag-header">Awaiting approval <span class="captures-number">(12)</span></div>
    <div class="drag-body"
         cdkDropList
         #awaiting="cdkDropList"
         [cdkDropListData]="awaitingList"
         [cdkDropListConnectedTo]="[approved,rejected,deleted]"
         (cdkDropListDropped)="drop($event)">

    </div>
  </div>
  <div class="drag-container">
    <div class="drag-header">Rejected <span class="captures-number">(10)</span></div>
    <div class="drag-body"
         cdkDropList
         #rejected="cdkDropList"
         [cdkDropListData]="rejectedList"
         [cdkDropListConnectedTo]="[approved,awaiting,deleted]"
         (cdkDropListDropped)="drop($event)"
    >

    </div>
  </div>
  <div class="drag-container">
    <div class="drag-header">Deleted <span class="captures-number">(2)</span></div>
    <div class="drag-body"
         cdkDropList
         #deleted="cdkDropList"
         [cdkDropListData]="deletedList"
         [cdkDropListConnectedTo]="[approved,awaiting,rejected]"
         (cdkDropListDropped)="drop($event)"
    >
    </div>
  </div>
</div>
