import { InstrumentNote } from './../../interfaces/instrument-note.interface';
import { GeneralHelpers } from 'src/app/shared/helpers/general/general.helper';
import { Instrument, InstrumentCatalog, InstrumentResponse } from './../../interfaces/instrument.interface';
import { TrayNote, TrayNoteResponse } from '../../interfaces/tray-note.interface';
import { Injectable, OnDestroy } from '@angular/core';
import { Tray } from '../../interfaces/tray.interface';
import { MessageService } from '../message/message.service';
import { ToastrService } from 'ngx-toastr';
import { TraySection } from '../../interfaces/tray-section.interface';
import { TraySectionNoteService } from '../tray-section-note/tray-section-note.service';
import { TraySectionService } from './../tray-section/tray-section.service';
import { TrayService } from 'src/app/shared/services/tray/tray.service';
import { ACTIVE_SECTION_UUID, ACTIVE_TRAY_UUID, MINOR_SET_ROUTE, ORGANIZATION_UUID } from '../../constants/general.constants';
import { NavigationEnd, Router } from '@angular/router';
import { filter, Subject, takeUntil } from 'rxjs';
import { InstrumentAssembleService } from '../instrument-assemble/instrument-assemble.service';
import {
  InstrumentAssemble,
  InstrumentAssembleCreate,
  InstrumentAssembleResponse,
  InstrumentAssembleUpdateCount,
  InstrumentAssembleUpdateOrder
} from '../../interfaces/instrument-assemble.interface';
import { TrayNoteService } from '../tray-note/tray-note.service';
import { InstrumentNoteService } from '../instrument-note/instrument-note.service';

@Injectable({
  providedIn: 'root'
})
export class MinorSetService implements OnDestroy {

  public trayNoteList: TrayNote[] = [];
  public selectedTrayNote!: TrayNote;

  public sectionNoteList: TrayNote[] = [];
  public selectedNote!: TrayNote;

  public sectionList: TraySection[] = [];
  public selectedSection!: TraySection;

  public sectionInstrumentsList: InstrumentAssemble[] = [];
  public instrumentNotes: InstrumentNote[] = [];

  public selectedInstrument: InstrumentAssemble | any = {
    medical_instrument: {
      loadedNotes: false,
    }
  };

  public trayData: Tray | any = {
    name: '',
  };

  public isAddSectionHidden: boolean = true;

  public loading: boolean = false;

  public sectionLoading: boolean = false;

  public noteLoading: boolean = false;

  public isEditMode: boolean = true;

  private _unsubscribeAll: Subject<any>;

  public $trayData = new Subject<Tray>();

  constructor(private messageService: MessageService,
    private trayService: TrayService,
    private traySectionService: TraySectionService,
    private traySectionNoteService: TraySectionNoteService,
    private trayNoteService: TrayNoteService,
    private instrumentAssembleService: InstrumentAssembleService,
    private instrumentNoteService: InstrumentNoteService,
    private toastr: ToastrService,
    private router: Router) {
    this._unsubscribeAll = new Subject();
    this.router.events
      .pipe(
        takeUntil(this._unsubscribeAll),
        filter((event: any): event is NavigationEnd => event instanceof NavigationEnd)
      )
      .subscribe((event: NavigationEnd) => {
        const isMinorSet = MINOR_SET_ROUTE.includes(event.url) ? true : false;
        if (isMinorSet) {
          this.getActiveTrayData();
        }
      });
  }

  ngOnDestroy(): void {
    // ─────────────────────────────────────────────────────────────────
    // *AutoUnsubscribe need ngOnDestroy to be present please do NOT delete
    // ─────────────────────────────────────────────────────────────────
    this._unsubscribeAll.next({});
    this._unsubscribeAll.complete();
  }

  // ─────────────────────────────────────────────────────────────────────
  // Tray Notes

  getTrayNoteData() {
    this.noteLoading = true;
    this.trayNoteService.get(this.getActiveTrayUuid()).subscribe({
      next: (organizationNoteResponse: TrayNoteResponse) => {
        this.trayNoteList = organizationNoteResponse.results;
        this.noteLoading = false;
      },
      error: () => {
        this.noteLoading = false;
      }
    })
  }

  deleteTrayNote(uuid: string) {
    this.noteLoading = true;
    this.trayNoteService.delete(this.getActiveTrayUuid(), uuid).subscribe({
      next: () => {
        const index = this.trayNoteList.findIndex((item) => item.uuid === uuid);
        this.trayNoteList.splice(index, 1);
        this.toastr.success("Tray note was deleted");
        this.noteLoading = false;
        this.messageService.sendMessage('deleteTrayNote');
      },
      error: () => {
        this.toastr.error("Tray note was not deleted");
        this.noteLoading = false;
        this.messageService.sendMessage('deleteTrayNote');
      }
    })
  }

  editTrayNote(uuid: string) {
    (this.selectedNote as any) = {};
    const index = this.trayNoteList.findIndex((item) => item.uuid === uuid);
    if (index > -1) {
      this.messageService.sendMessage('editTrayNote', {
        note: this.trayNoteList[index],
        index: index
      });
    }
  }

  // ─────────────────────────────────────────────────────────────────────────────
  // Section Notes

  getSectionNoteData(traySection: TraySection) {
    if (traySection === undefined || traySection.uuid === undefined) {
      return;
    }
    this.noteLoading = true;
    this.traySectionNoteService.get(this.getActiveTrayUuid(), traySection.uuid).subscribe({
      next: (organizationNoteResponse: TrayNoteResponse) => {
        this.sectionNoteList = organizationNoteResponse.results;
        this.noteLoading = false;
      },
      error: () => {
        this.noteLoading = false;
      }
    })
  }

  deleteSectionNote(uuid: string) {
    this.noteLoading = true;
    this.traySectionNoteService.delete(this.getActiveTrayUuid(), this.getActiveSectionUuid(), uuid).subscribe({
      next: () => {
        const index = this.sectionNoteList.findIndex((item) => item.uuid === uuid);
        this.sectionNoteList.splice(index, 1);
        this.toastr.success("Tray note was deleted");
        this.noteLoading = false;
        this.messageService.sendMessage('deleteTrayNote');
      },
      error: () => {
        this.toastr.error("Tray note was not deleted");
        this.noteLoading = false;
        this.messageService.sendMessage('deleteTrayNote');
      }
    })
  }

  editSectionNote(uuid: string) {
    (this.selectedNote as any) = {};
    const index = this.sectionNoteList.findIndex((item) => item.uuid === uuid);
    if (index > -1) {
      this.messageService.sendMessage('editSectionNote', {
        note: this.sectionNoteList[index],
        index: index
      });
    }
  }
  // ─────────────────────────────────────────────────────────────────────────────
  // Sections

  addSection(section: TraySection) {
    this.sectionList.push(section);
    this.setSelectedSection(section);
  }

  deleteSection(uuid: string) {
    this.sectionLoading = true;
    this.traySectionService.delete(this.getActiveTrayUuid(), uuid).subscribe({
      next: () => {
        const index = this.getSectionSelectionIndex(uuid);
        this.sectionList.splice(index, 1);
        this.toastr.success("Tray section was deleted");
        this.sectionLoading = false;
        this.setFirstSelctionSelected();
      },
      error: () => {
        this.toastr.error("Tray section was not deleted");
        this.sectionLoading = false;
      }
    })
  }

  editSection(uuid: string) {
    (this.selectedSection as any) = {};
    const index = this.getSectionSelectionIndex(uuid);
    if (index > -1) {
      this.messageService.sendMessage('editSection', {
        section: this.sectionList[index],
        index: index
      })
    }
  }

  setFirstSelctionSelected() {
    if (Array.isArray(this.sectionList) && this.sectionList.length > 0) {
      this.setSelectedSection(this.sectionList[0]);
    }
  }

  setSelectedSection(traySection: TraySection) {
    if (traySection.isSelected) {
      return;
    }
    this.cleanOnSwitchSection();
    this.removeSectionSelection();
    const index = this.getSectionSelectionIndex(traySection.uuid);
    this.sectionList[index].isSelected = true;
    this.setActiveSectionUuid(traySection);
    this.getSectionNoteData(traySection);
    this.getAssembleInstruments(traySection);
    this.isAddSectionHidden = true;
  }

  private getSectionSelectionIndex(uuid: string): number {
    const index = this.sectionList.findIndex((item) => item.uuid === uuid);
    return index === -1 ? 0 : index;
  }

  setActiveSectionUuid(traySection: TraySection) {
    if (traySection === undefined || traySection.uuid === undefined) {
      return;
    }
    localStorage.setItem(ACTIVE_SECTION_UUID, traySection.uuid);
  }

  getActiveSectionUuid(): string {
    const uuid = localStorage.getItem(ACTIVE_SECTION_UUID);
    return uuid ? uuid : '';
  }

  public removeSectionSelection() {
    for (let index = 0; index < this.sectionList.length; index++) {
      this.sectionList[index].isSelected = false;
    }
  }

  private setSectionList(trayData: Tray) {
    this.sectionList = trayData?.sections ? trayData?.sections : [];
  }

  // ─────────────────────────────────────────────────────────────────────────────
  // Instruments

  getAssembleInstruments(traySection: TraySection) {
    if (traySection === undefined || traySection.uuid === undefined) {
      return;
    }
    this.sectionLoading = true;
    this.instrumentAssembleService.get(0, 25, 'order', '', true, this.getActiveTrayUuid(), traySection.uuid).subscribe({
      next: (instrumentAssembleResponse: InstrumentAssembleResponse | any) => {
        if (Array.isArray(instrumentAssembleResponse.results)) {
          this.sectionInstrumentsList = this.processAssembledInstruments(instrumentAssembleResponse.results);
          //this.sectionInstrumentsList.sort((a,b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0));
        }
        this.sectionLoading = false;
      },
      error: () => {
        this.sectionLoading = false;
      }
    })
  }

  private processAssembledInstruments(data: InstrumentAssemble[]): InstrumentAssemble[] {
    if (Array.isArray(data) && data.length > 0) {
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        element.medical_instrument = GeneralHelpers.mapInstrumentData(element.medical_instrument);
      }
    }
    return data;
  }

  addAssembleInstrument(instrumentData: {
    instrumentCount: number,
    selectedInstrument: Instrument
  }) {
    const instrumentAssembleData: InstrumentAssembleCreate = {
      required_number: instrumentData?.instrumentCount,
      medical_instrument_uuid: instrumentData?.selectedInstrument?.uuid
    }

    if (instrumentAssembleData.required_number === undefined || instrumentAssembleData.medical_instrument_uuid === undefined) {
      this.toastr.warning('Cannot add assemble instrument', "Please try again");
      return;
    }

    if (Array.isArray(this.sectionInstrumentsList) && this.sectionInstrumentsList.length > 0) {
      const hasId: boolean = this.sectionInstrumentsList.some((item: InstrumentAssemble) => {
        return item.medical_instrument.uuid === instrumentAssembleData.medical_instrument_uuid;
      })
      if (hasId) {
        this.toastr.warning('Cannot add assemble instruemnt', "Already has instrument with this id");
        return;
      }
    }

    this.sectionLoading = true;

    this.instrumentAssembleService.create(this.getActiveTrayUuid(), this.getActiveSectionUuid(), instrumentAssembleData).subscribe({
      next: (data: InstrumentAssemble | any) => {
        data.medical_instrument = GeneralHelpers.mapInstrumentData(data.medical_instrument);
        this.sectionInstrumentsList.push(data);
        this.sectionLoading = false;
      },
      error: () => {
        this.sectionLoading = false;
      }
    })
  }

  editAssembleInstrumentCount(instrumentUuid: string, data: Partial<InstrumentAssembleUpdateCount>) {
    this.sectionLoading = true;
    this.instrumentAssembleService.update(this.getActiveTrayUuid(), this.getActiveSectionUuid(), instrumentUuid, data as any).subscribe({
      next: (instrumentAssemble: InstrumentAssemble | any) => {
        if (instrumentAssemble) {
          const index = this.sectionInstrumentsList.findIndex((item) => item.uuid === instrumentUuid);
          this.sectionInstrumentsList[index] = instrumentAssemble;
          //this.toastr.success("Assembled instrument count was updated");
        }
        this.sectionLoading = false;
      },
      error: () => {
        this.sectionLoading = false;
      }
    })
  }

  editAssembleInstrumentOrder(instrumentUUID: string, diff: number) {
    this.sectionLoading = true;
    const index = this.sectionInstrumentsList.findIndex((item) => item.medical_instrument.uuid === instrumentUUID);
    if(index >= 0) {
      this.sectionInstrumentsList[index].order = this.sectionInstrumentsList[index].order + diff;
      if (this.sectionInstrumentsList[index].order < 0) this.sectionInstrumentsList[index].order = 0;
      const data = {required_number: this.sectionInstrumentsList[index].required_number, order: this.sectionInstrumentsList[index].order};
      this.instrumentAssembleService.update(this.getActiveTrayUuid(), this.getActiveSectionUuid(), instrumentUUID, data as any).subscribe({
        next: (instrumentAssemble: InstrumentAssemble | any) => {
          if (instrumentAssemble) {
            this.sectionInstrumentsList[index] = instrumentAssemble;
            //this.toastr.success("Assembled instrument count was updated");
          }
          this.sectionLoading = false;
        },
        error: () => {
          this.sectionLoading = false;
        }
      })
    }
  }

  deleteAssembleInstrument(uuid: string) {
    this.sectionLoading = true;
    this.instrumentAssembleService.delete(this.getActiveTrayUuid(), this.getActiveSectionUuid(), uuid).subscribe({
      next: () => {
        this.sectionInstrumentsList = this.sectionInstrumentsList.filter(item => item.medical_instrument && item.medical_instrument.uuid && item.medical_instrument.uuid !== uuid);
        this.sectionLoading = false;
      },
      error: () => {
        this.toastr.error("Assembled instrument was not deleted");
        this.sectionLoading = false;
      }
    })
  }

  setInstrumentSelected(instrument: InstrumentAssemble) {
    if (instrument.isSelected) {
      return;
    }
    this.removeInstrumentSelection();
    const index = this.sectionInstrumentsList.findIndex((item) => item.uuid === instrument.uuid);
    this.sectionInstrumentsList[index].isSelected = true;
    this.selectedInstrument = instrument;
    //this.selectedInstrument.medical_instrument.loadedNotes = false;
    this.instrumentNoteService.getNoteData(instrument?.medical_instrument?.uuid);
    this.instrumentNoteService.$noteLoading.subscribe((value) => {
      this.selectedInstrument.medical_instrument.loadedNotes = !value;
    });
    this.instrumentNoteService.$mappedNoteList.subscribe((data: InstrumentNote[]) => {
      this.instrumentNotes = data;
      this.selectedInstrument.medical_instrument.notes = data;
    })
  }

  getActiveInstrumentUUID(): string {
    return this.selectedInstrument ? this.selectedInstrument.medical_instrument.uuid : '';
  }

  private removeInstrumentSelection() {
    for (let index = 0; index < this.sectionInstrumentsList.length; index++) {
      this.sectionInstrumentsList[index].isSelected = false;
    }
  }

  // ─────────────────────────────────────────────────────────────────────────────
  // Trays

  getActiveTrayData() {
    const trayUuid = this.getActiveTrayUuid();
    if (trayUuid === '') {
      return;
    }
    this.loading = true;
    this.trayService.getById(trayUuid).subscribe({
      next: (tray: Tray) => {
        this.trayData = tray;
        this.$trayData.next(tray);
        this.setSectionList(tray);
        this.setFirstSelctionSelected();
        this.canEditTray(tray)
        this.loading = false;
        this.messageService.sendMessage('')
      },
      error: () => {
        this.loading = false;
      }
    })
  }

  setTrayData(trayData: Tray) {
    this.trayData = trayData;
    this.setActiveTrayUuid(trayData);
    this.setSectionList(trayData);
  }

  setActiveTrayUuid(trayData: Tray) {
    if (trayData === undefined || trayData.uuid === undefined) {
      return;
    }
    localStorage.setItem(ACTIVE_TRAY_UUID, trayData.uuid);
  }

  getActiveTrayUuid(): string {
    const uuid = localStorage.getItem(ACTIVE_TRAY_UUID);
    return uuid ? uuid : '';
  }

  private canEditTray(tray: Tray): void {
    const organizationUuid = localStorage.getItem(ORGANIZATION_UUID) ? localStorage.getItem(ORGANIZATION_UUID) : '';
    this.isEditMode = tray?.organization_owner?.uuid === organizationUuid;
  }

  public cleanOnSwitchSection() {
    this.sectionNoteList = [];
    this.sectionInstrumentsList = [];
  }

  // ─────────────────────────────────────────────────────────────────────────────


}
