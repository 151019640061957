import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IntrumentSearchResultDialogComponent } from './intrument-search-result-dialog.component';
// ────────────────────────────────────────────────────────────────────────────────
// Material
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
// ────────────────────────────────────────────────────────────────────────────────
// Forms Module
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// ────────────────────────────────────────────────────────────────────────────────
// Search item
import { InstrumentSearchItemModule } from '../instrument-search-item/instrument-search-item.module';
// Grid module ────────────────────────────────────────────────────────────────────
import { FlexLayoutModule } from '@angular/flex-layout';
// ────────────────────────────────────────────────────────────────────────────────
// Custom scrollbar
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    // Material
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatToolbarModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    // Layout
    FlexLayoutModule,
    // Search item
    InstrumentSearchItemModule,
    // Custom scroll bar
    PerfectScrollbarModule,
    // Form module
    FormsModule,
    ReactiveFormsModule,
    // Translations
    TranslateModule.forChild(),
  ],
  declarations: [IntrumentSearchResultDialogComponent]
})
export class IntrumentSearchResultDialogModule { }
