import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InstrumentTableComponent } from './instrument-table/instrument-table.component';
import {MatSortModule} from "@angular/material/sort";
import {MatTableModule} from "@angular/material/table";
import {RouterModule} from "@angular/router";
import {FlexModule} from "@angular/flex-layout";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {NgxPermissionsModule} from "ngx-permissions";
import {TranslateModule} from "@ngx-translate/core";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatOptionModule} from "@angular/material/core";
import {MatSelectModule} from "@angular/material/select";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {InfiniteScrollModule} from "ngx-infinite-scroll";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {ReactiveFormsModule} from "@angular/forms";
import {MatTooltipModule} from "@angular/material/tooltip";

@NgModule({
  declarations: [
    InstrumentTableComponent
  ],
  exports: [
    InstrumentTableComponent
  ],
    imports: [
        CommonModule,
        MatSortModule,
        MatTableModule,
        RouterModule,
        FlexModule,
        MatButtonModule,
        MatIconModule,
        NgxPermissionsModule,
        TranslateModule,
        MatFormFieldModule,
        MatOptionModule,
        MatSelectModule,
        MatCheckboxModule,
        InfiniteScrollModule,
        MatProgressSpinnerModule,
        ReactiveFormsModule,
        MatTooltipModule
    ]
})
export class InstrumentsTableModule { }
