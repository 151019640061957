import {AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {INSTRUMENT_STATUSES, INSTRUMENTS_VISIBILITY} from "../../../constants/general.constants";
import {MatDialog} from "@angular/material/dialog";
import {InstrumentEditComponent} from "../../instrument-edit/instrument-edit.component";
import {environment} from '../../../../../environments/environment';
import {
  BehaviorSubject,
  debounceTime,
  distinctUntilChanged,
  fromEvent,
  map,
  take
} from "rxjs";
import {InstrumentsService} from "../../../services/instruments/instruments.service";
import {FormControl} from "@angular/forms";
import {ActivatedRoute} from "@angular/router";
import {MatSort, Sort} from "@angular/material/sort";
import {MatSelectChange} from "@angular/material/select";
import {OrganizationsService} from "../../../services/organizations/organizations.service";

@Component({
  selector: 'app-instrument-table',
  templateUrl: './instrument-table.component.html',
  styleUrls: ['./instrument-table.component.scss']
})
export class InstrumentTableComponent implements OnChanges, AfterViewInit {

  public updateInstrumentDialogRef: any;
  public visibilities = INSTRUMENTS_VISIBILITY;
  public instrumentStatuses = INSTRUMENT_STATUSES;
  public count$ = new BehaviorSubject(0);
  @Input() tableValue: any;
  @Input() tableDataSource = [];
  @Input() loading!: boolean
  @Output() getDataSource: EventEmitter<any> = new EventEmitter();
  @Output() sorting: EventEmitter<any> = new EventEmitter();
  @ViewChild('search') search!: ElementRef;
  @ViewChild(MatSort) sort!: MatSort;
  searchControl = new FormControl();
  teamName!: string;
  instFilters: any = {
    catalog: {filters: {q: ''}, sorting: null},
    'instruments-queue': {filters: {status: '1'}, sorting: null},
    'photo-queue': {filters: null, sorting: null},
    notes: {filters: null, sorting: null},
    cv: {filters: null, sorting: null},
  };

  constructor(
    public dialog: MatDialog,
    private readonly instrumentsService: InstrumentsService,
    private readonly route: ActivatedRoute,
    public organizationsService: OrganizationsService
  ) { }
  displayedColumns1: string[] = ['name', 'vendor__name', 'sku', 'actions'];
  // displayedColumns1: string[] = ['name', 'vendor__name', 'sku', 'actions', 'ljAdmin'];
  displayedColumns2: string[] = ['name', 'vendor__name', 'sku','status', 'is_organization_public', 'date_created', 'actions'];
  displayedColumns3: string[] = ['photo','organization_medical_instrument__medical_instrument__name', 'organization_medical_instrument__medical_instrument__vendor__name', 'organization_medical_instrument__medical_instrument__sku','status', 'date_created', 'actions'];
  displayedColumns4: string[] = ['name','vendor__name', 'sku', 'status', 'date', 'actions'];
  dataSource: any;

  instQFilterChange(field: string, data: any) {
    if(this.instFilters[this.tableValue].filters[field] && this.instFilters[this.tableValue].filters[field] !== data.value) {
      this.instFilters[this.tableValue].filters[field] = data.value;
      this.getDataSource.emit(this.instFilters[this.tableValue]);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.tableDataSource) {
      this.loading = changes?.loading.currentValue
      this.dataSource = new MatTableDataSource(changes.tableDataSource.currentValue)
      this.dataSource.sort = this.sort
    }
  }

  ngAfterViewInit(): void {
    if (this.search) {
      fromEvent(this.search.nativeElement, 'input')
        // @ts-ignore
        .pipe(map((event: Event) => (event.target as HTMLInputElement).value))
        .pipe(debounceTime(1000))
        .pipe(distinctUntilChanged())
        .subscribe(data => this.searchInstruments(data));
    }

  }

  onScroll() {
    this.getDataSource.emit();
  }

  public openUpdateCatalog(data: any, tabIndex:number) {
    this.updateInstrumentDialogRef = this.dialog.open(InstrumentEditComponent, {
      width: '1500px',
      autoFocus: false,
      data: {
        data,
        tabIndex
      }
    });
    this.updateInstrumentDialogRef.componentInstance.updateInstruments.subscribe(() => {
      this.getDataSource.emit(this.instFilters[this.tableValue]);
    })
  }

  deleteCatalogInstrument(data:any) {
    if(confirm('Delete current catalog instrument?')) {
      this.instrumentsService.delete(data.uuid).pipe(take(1)).subscribe(() => {
        this.getDataSource.emit(this.instFilters[this.tableValue]);
      });
    }
  }

  approveInstrument(data: any) {
    const status: FormData = new FormData()
    status.append('status', '2')
    this.instrumentsService.updateInstrumentPhoto(status, data.uuid)
      .pipe(take(1))
      .subscribe((res:any) => {
        data.status = res.status
      })
  }

  searchInstruments(q:any) {
      this.instFilters[this.tableValue].filters = {q};
      this.getDataSource.emit(this.instFilters[this.tableValue]);
  }

  statusMapping(data:number) {
    const status = this.instrumentStatuses.filter(statuses => statuses.value == data);
    return status[0].viewValue
  }

  acceptInstrumentFile(element:any){
    const req ={
      instrument_uuid: element.uuid,
      uuid: element.uuid
    }
    element.is_approved = true;
    this.instrumentsService.approveInstrumentPhoto(req, { is_approved: true }).pipe(take(1)).subscribe(() => {
      this.getDataSource.emit(this.instFilters[this.tableValue]);
    })
  }

  changeVisibility(event: MatSelectChange, instrument:any) {
    const req = {
      is_public: event.value,
    }
    this.instrumentsService.modifyNewHubInstrument(req, instrument.uuid).pipe(
      take(1)
    ).subscribe()
  }

  sortData(sort: Sort){
    this.instFilters[this.tableValue].sorting = sort;
    this.sorting.emit(this.instFilters[this.tableValue]);
  }

  deleteInstrumentFile(element:any) {
    if(confirm('Delete current catalog image?')) {
      element.isDeleted = true;
      const req ={
        instrument_uuid: element.uuid,
        uuid: element.uuid
      }
      this.instrumentsService.deleteInstrumentPhoto(req).pipe(take(1)).subscribe(() => {
        this.getDataSource.emit(this.instFilters[this.tableValue]);
      });
    }
  }

  addInstrument() {
    window.open(`${environment.addInstrumentRedirectUrl}`, '_blank')
  }
}
