import { Component, OnInit } from '@angular/core';
import {filter, fromEvent, map, merge, mergeMap, of, Subject, Subscription, takeUntil} from 'rxjs';
import {NgxPermissionsService, NgxRolesService} from "ngx-permissions";
import {AuthService} from "@auth0/auth0-angular";
import {PermissionsService} from "./shared/services/permissions/permission.service";
import {
  DARK_BACKGROUND_ROUTES,
  GUEST
} from "./shared/constants/general.constants";
import {GeneralHelpers} from "./shared/helpers/general/general.helper";
import {NavigationEnd, Router} from "@angular/router";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  networkStatus: boolean = true;
  networkStatus$: Subscription = Subscription.EMPTY;
  isDarkBG = false;

  private _unsubscribeAll: Subject<any>;

  constructor(private permissionsService: NgxPermissionsService,
              private rolesServices: NgxRolesService,
              private auth: AuthService,
              private permissionsLoader: PermissionsService,
              private router: Router) {
    this._unsubscribeAll = new Subject();
    this.router.events
      .pipe(
        takeUntil(this._unsubscribeAll),
        filter((event: any): event is NavigationEnd => event instanceof NavigationEnd)
      )
      .subscribe((event: NavigationEnd) => {
        const path = event.url.split('?');
        if(path && path[0]) {
          this.isDarkBG = !!DARK_BACKGROUND_ROUTES.includes(path[0]);
        }
      });
  }

  ngOnInit(): void {

    this.rolesServices.addRole(GUEST, [GUEST]);
    this.permissionsService.loadPermissions([GUEST]);

    this.auth.isAuthenticated$.pipe(
      mergeMap(async (status) => {
        if(status) {
          this.permissionsLoader.getPermissions().subscribe((profile: any) => {
            const permissions = GeneralHelpers.setRole(profile);
            this.permissionsLoader.processProfile(profile);
            if (Array.isArray(permissions) && permissions.length > 0) {
              this.rolesServices.addRole(permissions[0], permissions);
              this.permissionsService.loadPermissions(permissions);
            }
            const orgPer = profile.organizations.some((x:any) => x.name === 'LayerJot')
            this.permissionsLoader.orgPermission.next(orgPer);
          }, (err) => {
          })
        }
      })
    ).subscribe();
    this.checkNetworkStatus();
  }

  ngOnDestroy(): void {
    this.networkStatus$.unsubscribe();
    this._unsubscribeAll.next({});
    this._unsubscribeAll.complete();
  }

  checkNetworkStatus() {
    this.networkStatus = navigator.onLine;
    this.networkStatus$ = merge(
      of(null),
      fromEvent(window, 'online'),
      fromEvent(window, 'offline')
    )
      .pipe(map(() => navigator.onLine))
      .subscribe(status => {
        this.networkStatus = status;
      });
  }
}
