import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from "@angular/cdk/drag-drop";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {
   CATALOG_INSTRUMENT_STATUSES_NEW, CATALOG_INSTRUMENT_STATUSES_VISIBILITY,
  MAX_STRING_LENGTH
} from "../../../helpers/general/general.helper";
import {InstrumentsService} from "../../../services/instruments/instruments.service";
import {finalize, take,} from "rxjs";

@Component({
  selector: 'app-information-and-catalog',
  templateUrl: './information-and-catalog.component.html',
  styleUrls: ['./information-and-catalog.component.scss']
})
export class InformationAndCatalogComponent implements OnInit {

  @Input() instrumentData!: any;
  @Output() updateForm = new EventEmitter<any>();

  approved: any[] = [];
  waitingApproval: any[] = [];
  hidden: any[] = [];

  image: any;

  previews: string[] = [];

  public statuses = CATALOG_INSTRUMENT_STATUSES_NEW;
  public statusesVisibility = CATALOG_INSTRUMENT_STATUSES_VISIBILITY;
  public informationForm!: FormGroup;
  imagesIndexArray!: any[];
  loadingCatalogImage = false;
  loadingFiles = false;
  loadingDeleteAwaitingBtn: any = [];
  loadingDeleteHiddenBtn: any = [];

  constructor(
    private readonly fb: FormBuilder,
    private readonly instrumentsService: InstrumentsService,
  ) { }

  ngOnInit(): void {
    this.initForm();
    this.getFiles();
  }

  getFiles(onlyAwaiting = false) {
    if(!onlyAwaiting) {
      this.loadingFiles = true;
    }
    this.instrumentData.data.files = [];
    this.instrumentsService.getInstrumentFiles(this.instrumentData.data.uuid).pipe(
      take(1)
    ).subscribe( (res:any) => {
      this.instrumentData.data.files = res;
      this.waitingApproval = [...this.instrumentData.data.files.filter((x:any) => x.is_approved === false && x.is_archived === false)];
      if(onlyAwaiting) {
        this.loadingCatalogImage = false;
      } else {
        this.approved = [...this.instrumentData.data.files.filter((x:any) => x.is_approved === true)];
        this.hidden = [...this.instrumentData.data.files.filter((x:any) => x.is_archived === true)];
        this.approved.sort((a, b) => a.order - b.order);
        this.loadingFiles = false;
      }
    }, () => {
      this.loadingFiles = false;
      if(onlyAwaiting) {
        this.loadingCatalogImage = false;
      }
    })
  }

  updateFileRow(row: any) {
    const instIndex = this.instrumentData.data.files.findIndex((r: any) => r.uuid === row.uuid);
    if(instIndex >= 0) {
      this.instrumentData.data.files[instIndex] = row;
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      if (event.container.id === 'approvedList') {
        this.approved = [...event.container.data];
        this.updatedImageOrder(this.approved);
      }
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
      if (event.container.id === 'approvedList') {
        this.approved = [...event.container.data]
        this.updateImageStatus(event.item.data, event.currentIndex === 0, this.approved);
      } else if (event.container.id === 'waitingApprovalList'){
        this.updateImageStatus(event.item.data);
        this.waitingApproval = [...event.container.data]
      } else if (event.container.id === 'hiddenList') {
        this.hideImage(event.item.data);
        this.hidden = [...event.container.data]
      }
    }
  }

  updatedImageOrder(list:any) {
    const indexArr:any = []
    list.forEach((item:any, index:any) => {
      const setObj = {file_uuid: item.uuid, order:index}
      indexArr.push(setObj);
    });
    this.instrumentsService.updateInstrumentPhotoIndex(this.instrumentData.data.uuid, {file_orders: JSON.stringify(indexArr)}).pipe(take(1)).subscribe(() => {
      indexArr.forEach((row: any) => {
        const instIndex = this.instrumentData.data.files.findIndex((r: any) => r.uuid === row.file_uuid);
        if(instIndex >= 0) {
          this.instrumentData.data.files[instIndex].order = row.order;
        }
      });
    });
  }

  getImages(event: Event) {
    // @ts-ignore
    const selectedFiles = event.target.files;
    if (selectedFiles) {
        this.loadingCatalogImage = true;
        const files: FormData = new FormData();
        for (let i = 0; i < selectedFiles.length; i++) {
          files.append('files', selectedFiles[i], selectedFiles[i].name);
        }
        this.instrumentsService.updateInstrumentPhoto(files, this.instrumentData.data.uuid).subscribe((res: any) => {
          this.getFiles(true);
        });

    }
  }


  updateImageStatus(elem:any, isMainImage = false, orderList: any = null) {
    const is_approved:any = !elem.is_approved
    const formData: FormData = new FormData();
    formData.append('is_approved', is_approved);
    if(isMainImage) {
      formData.append('is_main_image', 'true');
    }
    this.instrumentsService.updateFileStatus(elem, formData, this.instrumentData.data.uuid).subscribe((res) => {
      this.updateFileRow(res);
      if(orderList) {
        this.updatedImageOrder(orderList);
      }
    });
  }

  hideImage(elem:any) {
    const is_hidden:any = true;
    const formData: FormData = new FormData();
    formData.append('is_archived', is_hidden)
    this.instrumentsService.hideImage(elem, formData, this.instrumentData.data.uuid).pipe(take(1)).subscribe((res) => {
      this.updateFileRow(res);
    });
  }

  submitForm() {
    //this.updatedImageOrder(this.approved)
    this.instrumentsService.update(this.instrumentData.data.uuid, this.informationForm.value).pipe(
      take(1),
      finalize(() => {
        this.updateForm.emit();
      })
    ).subscribe();

  }

  deleteFile(elem:any, index:number) {
    this.loadingDeleteAwaitingBtn[index] = true;
    this.instrumentsService.deleteFile(this.instrumentData.data.uuid, elem.uuid ).subscribe(() => {
      this.waitingApproval.splice(index, 1);
      this.instrumentData.data.files = this.instrumentData.data.files.filter((r: any) => r.uuid !== elem.uuid);
      this.loadingDeleteAwaitingBtn[index] = false;
    }, () => {
      this.loadingDeleteAwaitingBtn[index] = false;
    });
  }

  deleteFileHidden(elem:any, index:number) {
    this.loadingDeleteHiddenBtn[index] = true;
    this.instrumentsService.deleteFile(this.instrumentData.data.uuid, elem.uuid ).subscribe(() => {
      this.hidden.splice(index, 1);
      this.instrumentData.data.files = this.instrumentData.data.files.filter((r: any) => r.uuid !== elem.uuid);
      this.loadingDeleteHiddenBtn[index] = false;
    }, () => {
      this.loadingDeleteHiddenBtn[index] = false;
    });
  }

  initForm() {
    this.informationForm = this.fb.group({
      name: [
        this.instrumentData.data?.name ?? '',
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(MAX_STRING_LENGTH),
        ],
      ],
      status: [
        this.instrumentData.data.status ? this.instrumentData.data.status : '',
      ],
      is_public: [
        this.instrumentData.data.is_organization_public,
      ],
    });
  }


}
