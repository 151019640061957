import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './shared/guards/auth.guard';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { ORGANIZATION_ADMIN, ORGANIZATION_EDITOR, PUBLIC_CONTRIBUTOR, STAFF, SUPER_USER, GUEST } from './shared/constants/general.constants';
import { OrganizationUUIDControlGuard } from './shared/guards/organization-uuid.guard';
import {ReportsComponent} from "./pages/reports/reports.component";

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () =>
      import('./pages/home/home.module').then((mod) => mod.HomeModule),
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        only: [SUPER_USER, ORGANIZATION_ADMIN, ORGANIZATION_EDITOR, PUBLIC_CONTRIBUTOR, STAFF, GUEST]
      }
    }
  },
  {
    path: 'settings',
    loadChildren: () =>
      import('./pages/settings/settings.module').then((mod) => mod.SettingsModule),
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        only: [SUPER_USER, ORGANIZATION_ADMIN, STAFF]
      }
    }
  },
  {
    path: 'team',
    loadChildren: () =>
      import('./pages/people/people.module').then((mod) => mod.PeopleModule),
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        only: [SUPER_USER, ORGANIZATION_ADMIN, ORGANIZATION_EDITOR, PUBLIC_CONTRIBUTOR, STAFF, GUEST]
      }
    }
  },
  {
    path: 'instruments',
    loadChildren: () =>
      import('./pages/instruments/instruments.module').then((mod) => mod.InstrumentsModule),
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        only: [SUPER_USER, ORGANIZATION_ADMIN, ORGANIZATION_EDITOR, STAFF, GUEST]
      }
    }
  },
  {
    path: 'trays',
    loadChildren: () =>
      import('./pages/trays/trays.module').then((mod) => mod.TraysModule),
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        only: [SUPER_USER, ORGANIZATION_ADMIN, ORGANIZATION_EDITOR, PUBLIC_CONTRIBUTOR, STAFF, GUEST]
      }
    }
  },
  // {
  //   path: 'reports',
  //   loadChildren: () =>
  //     import('./pages/reports/reports.module').then((mod) => mod.ReportsModule),
  //   canActivate: [],
  //   data: {
  //     permissions: {
  //       only: [SUPER_USER, ORGANIZATION_ADMIN, ORGANIZATION_EDITOR, STAFF]
  //     }
  //   }
  // },
  {
    path: 'tray-builder',
    loadChildren: () =>
      import('./pages/minor-set/minor-set.module').then((mod) => mod.MinorSetModule),
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        only: [SUPER_USER, ORGANIZATION_ADMIN, ORGANIZATION_EDITOR, STAFF]
      }
    }
  },
  {
    path: 'invite',
    loadChildren: () =>
      import('./pages/invite/invite.module').then(
        (mod) => mod.InviteModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'instruments-table',
    loadChildren: () => import('./shared/modules/instruments-table/instruments-table.module').then((mod) => mod.InstrumentsTableModule)
  },
  {
    path: 'catalog',
    loadChildren: () => import('./pages/catalog/catalog.module').then((mod) => mod.CatalogModule)
  },
  {
    path: 'instruments-queue',
    loadChildren: () => import('./pages/instruments-queue/instruments-queue.module').then((mod) => mod.InstrumentsQueueModule)
  },
  {
    path: 'photo-queue',
    loadChildren: () => import('./pages/photo-queue/photo-queue.module').then((mod) => mod.PhotoQueueModule)
  },
  {
    path: 'notes-queue',
    loadChildren: () => import('./pages/notes/notes.module').then((mod) => mod.NotesModule)
  },
  {
    path: 'cv-queue',
    loadChildren: () => import('./pages/cv/cv.module').then((mod) => mod.CvModule)
  },
  {
    path: '500',
    loadChildren: () =>
      import('./static-pages/internalServerError/internalServerError.module').then(
        (mod) => mod.InternalServerErrorModule
      ),
  },
  {
    path: '404',
    loadChildren: () =>
      import('./static-pages/pageNotFound/pageNotFound.module').then(
        (mod) => mod.PageNotFoundModule
      ),
  },
  {
    path: 'not-authenticated',
    loadChildren: () =>
      import('./static-pages/notAuthenticated/notAuthenticated.module').then(
        (mod) => mod.NotAuthenticatedModule
      ),
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./pages/entry-point/entry-point.module').then(
        (mod) => mod.EntryPointModule
      ),
  },
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full',
  },
  // {
  //   path: '**',
  //   redirectTo: '404',
  //   pathMatch: 'full',
  // },
];



@NgModule({
  imports: [RouterModule.forRoot(routes)],
  providers: [
    OrganizationUUIDControlGuard
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
