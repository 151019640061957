import {Component, Input, OnInit} from '@angular/core';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from "@angular/cdk/drag-drop";
import {InstrumentCaptureService} from "../../../services/instrument-capture/instrument-capture.service";
import {take} from "rxjs";


@Component({
  selector: 'app-cv-library',
  templateUrl: './cv-library.component.html',
  styleUrls: ['./cv-library.component.scss']
})
export class CvLibraryComponent implements OnInit
{

  @Input() instrumentData:any;
  approvedList: any[] = [

  ];
  awaitingList: any[] = [];
  rejectedList: any[] = [];
  deletedList: any[] = [];

  constructor(
    private cvService: InstrumentCaptureService

  ) { }

  ngOnInit() {
    this.getCaptures()
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );

    }
  }

  getCaptures() {
    console.log('kdhasukyasukdg')
    this.cvService.getById(this.instrumentData.data.id).pipe(take(1)).subscribe( (res:any) => {
        console.log(res, 'from capture edit')
    }
    )
  }

}
