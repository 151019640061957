<div class="container">
  <form class="left-info" [formGroup]="informationForm" *ngIf="informationForm">
    <div class="main-info">
      <span class="info-input-container">
        <span class="input-label">
          Vendor/Mnf:
        </span>
        <input type="text" class="info-input" [disabled]="true" [value]="this.instrumentData?.data?.vendor?.name">
      </span>
      <span class="info-input-container">
        <span class="input-label">
          SKU:
        </span>
        <input type="text" class="info-input" [disabled]="true" [value]="this.instrumentData?.data?.sku">
      </span>
      <span class="info-input-container">
        <span class="input-label">
          Description:
        </span>
        <textarea rows="3" cols="23" class="info-input" [disabled]="true" [value]="this.instrumentData?.data?.description"></textarea>
      </span>
      <span class="info-input-container">
        <span class="input-label">
          Status:
        </span>
        <mat-select formControlName="status">
            <mat-option *ngFor="let status of statuses" [value]="status.value">
              {{ status.viewValue }}
            </mat-option>
          </mat-select>
      </span>
      <span class="info-input-container">
        <span class="input-label">
          Visibility:
        </span>
        <mat-select formControlName="is_public">
            <mat-option
              *ngFor="let statusVisibility of statusesVisibility"
              [value]="statusVisibility.value"
            >
              {{ statusVisibility.viewValue }}
            </mat-option>
          </mat-select>
      </span>
    </div>
<!--    <div class="steris-support">-->
<!--      <h3 class="text-header">STERIS support view</h3>-->
<!--      <span class="info-input-container">-->
<!--        <span class="input-label">-->
<!--          SPM_ID-->
<!--        </span>-->
<!--        <input type="text" class="info-input" formControlName="spm_id">-->
<!--      </span>-->
<!--      <span class="info-input-container">-->
<!--        <span class="input-label">-->
<!--          SPM_Global_ID-->
<!--        </span>-->
<!--        <input type="text" class="info-input" formControlName="global_id">-->
<!--      </span>-->

<!--    </div>-->
<!--    <div class="layerjot-support">-->
<!--      <h3 class="text-header">LayerJot Admin view</h3>-->
<!--      <span class="info-input-container">-->
<!--        <span class="input-label">-->
<!--          Team-->
<!--        </span>-->
<!--        <input type="text" class="info-input" formControlName="team">-->
<!--      </span>-->
<!--      <span class="info-input-container">-->
<!--        <span class="input-label mr-15">-->
<!--          LJUUID-->
<!--        </span>-->
<!--        <input type="text" class="info-input" formControlName="ljuuid">-->
<!--      </span>-->
<!--    </div>-->
  </form>
  <div class="multiple-drag-and-drop">
  <div class="right-photo">
    <div class="text-container">
      <span class="area-text">Main</span>
    </div>
    <div
      cdkDropList
      #approvedList="cdkDropList"
      class="drag-n-drop-photo"
      [cdkDropListData]="approved"
      [cdkDropListConnectedTo]="[waitingApprovalList, hiddenList]"
      (cdkDropListDropped)="drop($event)"
    >
    <div class="drag-photo" *ngFor="let photo of approved" cdkDrag [cdkDragData]="photo">
      <img src="{{photo.url}}" alt="" class="catalog-photo" style="height: 125px; width: 125px;" matTooltip="{{photo?.date_created | date}}" matTooltipPosition="above">
    </div>
  </div>
  </div>
  <div class="awaiting-approval-container right-photo">
      <span class="area-text">Images yet to be approved</span>
    <div cdkDropList
         #waitingApprovalList="cdkDropList"
         class="drag-n-drop-photo"
         [cdkDropListData]="waitingApproval"
         [cdkDropListConnectedTo]="[approvedList, hiddenList]"
         (cdkDropListDropped)="drop($event)"
    >
      <div class="upload-area">
        <input type="file" multiple="multiple" (change)="getImages($event)" placeholder="upload" accept="image/*">
        <img src="/assets/images/add-photo.svg" alt="" class="upload-area-placeholder">
      </div>
      <div class="drag-photo" *ngFor="let photo of waitingApproval; let i = index" cdkDrag [cdkDragData]="photo">
        <img src="{{photo.url}}" alt="" class="catalog-photo" style="height: 125px; width: 125px;" matTooltip="{{photo?.date_created | date}}" matTooltipPosition="above" >
        <button mat-icon-button matTooltip="Delete intrument"  color="warn">
          <mat-icon aria-label="Delete" (click)="deleteFile(photo, i)">delete</mat-icon>
        </button>
      </div>
    </div>
  </div>
    <div class="hidden-container right-photo">
      <span class="area-text">Hidden images</span>
      <div cdkDropList
           #hiddenList="cdkDropList"
           class="drag-n-drop-photo"
           [cdkDropListData]="hidden"
           [cdkDropListConnectedTo]="[approvedList, waitingApprovalList]"
           (cdkDropListDropped)="drop($event)"
      >
        <div class="drag-photo" *ngFor="let photo of hidden; let i = index" cdkDrag [cdkDragData]="photo">
          <img src="{{photo.url}}" alt="" class="catalog-photo hidden-photo" style="height: 125px; width: 125px;" matTooltip="{{photo?.date_created | date}}" matTooltipPosition="above">
        </div>
      </div>
    </div>

  </div>
  <button mat-button class="update-instrument" (click)="submitForm()">
     Update instrument info
  </button>
</div>
