import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Observable, tap} from "rxjs";
import {AuthService} from "@auth0/auth0-angular";

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private auth: AuthService
  ) {}

  isAuth():Observable<boolean> {
    return new Observable((sub) => {
      const urlParams = new URLSearchParams(window.location.search);
      if(urlParams.get('code') && urlParams.get('state')) {
        sub.next(true);
      } else {
        this.auth.isAuthenticated$.subscribe((val) => {
          sub.next(val);
        });
      }
    });
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.isAuth().pipe(
      tap(async (status) => {
        if (!status) {
          this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
        }
      })
    );
  }
}
