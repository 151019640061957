import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from "@angular/cdk/drag-drop";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {
   CATALOG_INSTRUMENT_STATUSES_NEW, CATALOG_INSTRUMENT_STATUSES_VISIBILITY,
  MAX_STRING_LENGTH
} from "../../../helpers/general/general.helper";
import {InstrumentsService} from "../../../services/instruments/instruments.service";
import {finalize, take,} from "rxjs";

@Component({
  selector: 'app-information-and-catalog',
  templateUrl: './information-and-catalog.component.html',
  styleUrls: ['./information-and-catalog.component.scss']
})
export class InformationAndCatalogComponent implements OnInit {

  @Input() instrumentData!: any;
  @Output() updateForm = new EventEmitter<any>();

  approved: any[] = [];
  waitingApproval: any[] = [];
  hidden: any[] = [];

  image: any;
  selectedFiles?: FileList;

  previews: string[] = [];

  public statuses = CATALOG_INSTRUMENT_STATUSES_NEW;
  public statusesVisibility = CATALOG_INSTRUMENT_STATUSES_VISIBILITY;
  public informationForm!: FormGroup;
  imagesIndexArray!: any[];

  constructor(
    private fb: FormBuilder,
    private instrumentsService: InstrumentsService,
  ) { }

  ngOnInit(): void {
    this.initForm();
    this.waitingApproval = [...this.instrumentData.data.files.filter((x:any) => x.is_approved === false && x.is_archived === false)];
    this.approved = [...this.instrumentData.data.files.filter((x:any) => x.is_approved === true)];
    this.hidden = [...this.instrumentData.data.files.filter((x:any) => x.is_archived === true)];
    this.approved.sort((a, b) => a.order - b.order);
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.container.id === 'cdk-drop-list-0') {
      if (event.currentIndex === 0) {
        const is_main_image:any = true;
        const formData: FormData = new FormData();
        formData.append('is_main_image', is_main_image)
        this.instrumentsService.updateFileStatus(event.item.data, formData,this.instrumentData.data.uuid)
          .pipe(take(1))
          .subscribe()
      }
    }
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
      if (event.container.id === 'cdk-drop-list-0') {
        this.approved = [...event.container.data]
        this.updateImageStatus(event.item.data)
      } else if (event.container.id === 'cdk-drop-list-1'){
        this.updateImageStatus(event.item.data)
        this.waitingApproval = [...event.container.data]
      } else if (event.container.id === 'cdk-drop-list-2') {
        this.hideImage(event.item.data)
        this.hidden = [...event.container.data]
      }
    }
  }

  updatedImageOrder(list:any) {
    const indexArr:any = []
    list.forEach((item:any, index:any) => {
      const setObj = {file_uuid: item.uuid, order:index}
      indexArr.push(setObj);
    });
    this.instrumentsService.updateInstrumentPhotoIndex(this.instrumentData.data.uuid, {file_orders: JSON.stringify(indexArr)}).pipe
    (take(1))
      .subscribe()
  }

  getImages(event: Event) {
    // @ts-ignore
    this.selectedFiles = event.target.files;
      if (this.selectedFiles) {
      for (let i = 0; i < this.selectedFiles.length; i++) {
        const files: FormData = new FormData();
        files.append('files', this.selectedFiles[i], this.selectedFiles[i].name);
        this.instrumentsService.updateInstrumentPhoto(files, this.instrumentData.data.uuid).subscribe( (res:any) => {
          this.waitingApproval = [...res.files.filter((x:any) => x.is_approved === false)];
        });
      }
    }
  }


  updateImageStatus(elem:any) {
    const is_approved:any = !elem.is_approved
    const formData: FormData = new FormData();
    formData.append('is_approved', is_approved)
    this.instrumentsService.updateFileStatus(elem, formData, this.instrumentData.data.uuid).subscribe(
      (res:any) => {
      }
    )
  }

  hideImage(elem:any) {
    const is_hidden:any = true;
    const formData: FormData = new FormData();
    formData.append('is_archived', is_hidden)
    this.instrumentsService.hideImage(elem, formData, this.instrumentData.data.uuid).pipe(take(1)).subscribe()
  }

  submitForm() {
    this.updatedImageOrder(this.approved)
    this.instrumentsService.update(this.instrumentData.data.uuid, this.informationForm.value).pipe(
      take(1),
      finalize(() => {
        this.updateForm.emit();
      })
    ).subscribe();

  }

  deleteFile(elem:any, index:number) {
    this.instrumentsService.deleteFile(this.instrumentData.data.uuid, elem.uuid ).pipe(
      finalize(() => {
        this.waitingApproval.splice(index, 1)
      })
    ).subscribe()
  }

  initForm() {
    this.informationForm = this.fb.group({
      description: [
        this.instrumentData.data.description ? this.instrumentData.data.description : '',
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(MAX_STRING_LENGTH),
        ],
      ],
      status: [
        this.instrumentData.data.status ? this.instrumentData.data.status : '',
      ],
      is_public: [
        this.instrumentData.data.is_organization_public,
      ],
      // spm_id: ['234wer23r5423'],
      // global_id: ['ksdf8asdfkasdf98sf'],
      // team: ['LPCH'],
      // ljuuid: ['70b1b54b-4f46-4778-af58-135bdd6a54fd']
    });
  }


}
