import { Component, OnInit } from '@angular/core';
import {filter, fromEvent, map, merge, mergeMap, of, Subject, Subscription, takeUntil} from 'rxjs';
import {NgxPermissionsService, NgxRolesService} from "ngx-permissions";
import {AuthService} from "@auth0/auth0-angular";
import {PermissionsService} from "./shared/services/permissions/permission.service";
import {
  DARK_BACKGROUND_ROUTES,
  GUEST
} from "./shared/constants/general.constants";
import {NavigationEnd, Router} from "@angular/router";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  networkStatus: boolean = true;
  networkStatus$: Subscription = Subscription.EMPTY;
  isDarkBG = false;

  private readonly _unsubscribeAll: Subject<any>;

  constructor(private readonly permissionsService: NgxPermissionsService,
              private readonly rolesServices: NgxRolesService,
              private readonly auth: AuthService,
              private readonly permissionsLoader: PermissionsService,
              private readonly router: Router) {
    this._unsubscribeAll = new Subject();
    this.router.events
      .pipe(
        takeUntil(this._unsubscribeAll),
        filter((event: any): event is NavigationEnd => event instanceof NavigationEnd)
      )
      .subscribe((event: NavigationEnd) => {
        const path = event.url.split('?');
        if(path && path[0]) {
          this.isDarkBG = !!DARK_BACKGROUND_ROUTES.includes(path[0]);
        }
      });
  }

  ngOnInit(): void {

    this.rolesServices.addRole(GUEST, [GUEST]);
    this.permissionsService.loadPermissions([GUEST]);

    this.auth.isAuthenticated$.pipe(
      mergeMap(async (status) => {
        if(status) {
          this.permissionsLoader.loadPermissions();
        }
      })
    ).subscribe();
    this.checkNetworkStatus();
  }

  ngOnDestroy(): void {
    this.networkStatus$.unsubscribe();
    this._unsubscribeAll.next({});
    this._unsubscribeAll.complete();
  }

  checkNetworkStatus() {
    this.networkStatus = navigator.onLine;
    this.networkStatus$ = merge(
      of(null),
      fromEvent(window, 'online'),
      fromEvent(window, 'offline')
    )
      .pipe(map(() => navigator.onLine))
      .subscribe(status => {
        this.networkStatus = status;
      });
  }
}
