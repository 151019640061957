import {Component, Input, OnInit} from '@angular/core';
import {InstrumentNoteService} from "../../../services/instrument-note/instrument-note.service";
import {take} from "rxjs";
import {CdkDragDrop, moveItemInArray, transferArrayItem} from "@angular/cdk/drag-drop";
import {FormBuilder, FormGroup} from "@angular/forms";

@Component({
  selector: 'app-notes-edit',
  templateUrl: './notes-edit.component.html',
  styleUrls: ['./notes-edit.component.scss']
})
export class NotesEditComponent implements OnInit{

  @Input() instrumentData:any;
  approvedList: any[] = [];
  noNotes = false
  public notesForm!: FormGroup;


  waitingList: any[] = [];

  constructor(
    private notesService: InstrumentNoteService,
    private fb: FormBuilder,
  ) { }

  ngOnInit() {
    this.initForm();
    this.getNotes();
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
      if (event.container.id === 'cdk-drop-list-0') {
      } else if (event.container.id === 'cdk-drop-list-1'){

      }
    }
  }

  getNotes() {
    this.notesService.getInstrumentNote(this.instrumentData.data.uuid).pipe(
      take(1)
    ).subscribe( (res:any) => {
      this.approvedList = [...res.filter((x:any) => x.status == 2)]
      this.noNotes = res.some((note:any) => note.status == 1)
    })
  }

  getMedia(event: Event) {
    // @ts-ignore
    const file = event.target.files[0];
    if (file) {

    }
  }

  addNote() {
    this.notesService.addInstrumentNote(this.notesForm.value, this.instrumentData.data.uuid).pipe(take(1)).subscribe( (res:any) =>{
    })
  }

  resetForm() {
    this.notesForm.reset();
  }

  initForm() {
    this.notesForm = this.fb.group({
      title: [],
      description: [],
      is_public: [false],
      files: ['']
    });
  }

}
