import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InstrumentSearchItemComponent } from './instrument-search-item.component';
// ────────────────────────────────────────────────────────────────────────────────
// Material
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
// ────────────────────────────────────────────────────────────────────────────────
// Layout
import { FlexLayoutModule } from '@angular/flex-layout';
// ────────────────────────────────────────────────────────────────────────────────
// Custom scrollbar
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    // Material
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    // Layout
    FlexLayoutModule,
    // Custom scroll bar
    PerfectScrollbarModule,
    // Translations
    TranslateModule.forChild(),
  ],
  declarations: [InstrumentSearchItemComponent],
  exports: [InstrumentSearchItemComponent]
})
export class InstrumentSearchItemModule { }
