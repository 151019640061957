import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GeneralHelpers } from '../../helpers/general/general.helper';
import { API_BASE_V2, CATALOG } from '../../constants/general.constants';
import { Observable } from 'rxjs';
import { TrayResponse } from '../../interfaces/tray.interface';
import { TraySection } from '../../interfaces/tray-section.interface';

const TRAY = 'tray';
const HUB = 'hub'
const SECTION = 'section';

@Injectable({
  providedIn: 'root'
})
export class TraySectionService {

  constructor(private http: HttpClient) { }

  // ────────────────────────────────────────────────────────────────────────────────
  // Create tray

  create(trayUuid: string, body: TraySection): Observable<any> {
    (body as any) = GeneralHelpers.toMultipartFormData(body);
    return this.http.post(
      `${API_BASE_V2}${HUB}/${CATALOG}/${TRAY}/${trayUuid}/${SECTION}/`,
      body
    );
  }

  // ────────────────────────────────────────────────────────────────────────────────
  // Read
  // "https://dev-api.layerjot.com/api/1.0/hub/catalog/tray/?is_owned_trays=false&limit=10&offset=10"

  get(
    trayUuid: string,
    offset: number = 0,
    limit: number = 25,
    ordering: string = '',
    search: string = '',
    name: string = '',
  ): Observable<TrayResponse> {
    const options = {
      params: GeneralHelpers.getParams({ offset, limit, ordering, search, name }),
    };
    return this.http.get<TrayResponse>(
      `${API_BASE_V2}${HUB}/${CATALOG}/${TRAY}/${trayUuid}/${SECTION}/`, options
    );
  }

  // ────────────────────────────────────────────────────────────────────────────────
  // Update

  update(trayUuid: string | any, traySectionUuid: string | any, data: TraySection): Observable<Object> {
    (data as any) = GeneralHelpers.toMultipartFormData(data);
    return this.http
      .put<TraySection>(`${API_BASE_V2}${HUB}/${CATALOG}/${TRAY}/${trayUuid}/${SECTION}/${traySectionUuid}/`, data);
  }

  // ────────────────────────────────────────────────────────────────────────────────
  // Delete

  delete(trayUuid: string, traySectionUuid: string) {
    return this.http.delete(
      `${API_BASE_V2}${HUB}/${CATALOG}/${TRAY}/${trayUuid}/${SECTION}/${traySectionUuid}/`
    );
  }

}

