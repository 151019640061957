<mat-card class="mat-elevation-z0 w100 overflow" [ngClass]="{ 'p-0 m-0': hasResults }">
  <button mat-button class="close" [mat-dialog-close]="true">
    <mat-icon>close</mat-icon>
  </button>
  <div *ngIf="hasResults">
    <mat-card-content class="instrument-wrapper"
      ><perfect-scrollbar class="instrument-inner-wrapper">
        <ng-container *ngFor="let instrument of data.data.results">
          <div class="instrument-item">
            <app-instrument-search-item
              [instrumentData]="instrument"
              (click)="setSelected(instrument.uuid)"
            ></app-instrument-search-item>
          </div>
        </ng-container>
      </perfect-scrollbar>
    </mat-card-content>
    <mat-card-actions
      fxLayout="row"
      fxLayoutAlign="space-between center"
      class="instrument-actions"
    >
      <p class="instrument-actions-text">Select the instrument and specify how many are required</p>
      <input
        type="number"
        min="1"
        max="99"
        maxlength="2"
        class="form-control"
        placeholder="1"
        onkeyup="if(parseInt(this.value)>99){ this.value = 99; return false; }"
        [(ngModel)]="data.data.instrumentCount"
      />
      <button
        mat-button
        class="tray-button blue small"
        aria-label="Create organization"
        [class.loading]="loading"
        [disabled]="
          loading || data.data.instrumentCount === 0 || !data.data.selectedInstrument
        "
        [mat-dialog-close]="data.data"
      >
        Add selected
      </button>
    </mat-card-actions>
  </div>
  <div *ngIf="!hasResults" fxLayout="column" fxLayoutAlign="center center">
    <div class="alert alert-info" role="alert">
      Sorry, we could not find <a href="#" class="alert-link">"{{ data.q }}"</a> in
      the initial directory of over <strong>65,000</strong> items.
    </div>
    <div
      fxLayout="row"
      fxLayoutAlign="space-between center"
      fxLayoutGap="20px"
      class="mt-2 mb-3"
    >
      <button
        mat-button
        class="tray-button orange"
        aria-label="Send invite code"
        [class.loading]="loading"
        (click)="openinstrumentContributionDialog()"
      >
        Add an instrument
      </button>
      <button
        mat-button
        class="tray-button blue"
        aria-label="Send invite code"
        [class.loading]="loading"
        (click)="sendInsRequest()"
      >
        Request
      </button>
    </div>
    <p class="mt-3">Maybe this will brighten your day...</p>
    <img [src]="imagePreview" *ngIf="imageLoaded">
    <p class="mt-1" *ngIf="imageLoaded">Image by unsplash</p>
  </div>
</mat-card>
