<ng-container *ngIf="tableValue === 'catalog'">
<div class="table-container">
  <div class="search-team-container">
    <div class="left-wrapper">
      <span class="private-name">{{ teamName }} Private Catalog</span>
      <span class="search-input-wrapper" fxFlex="30">
      <mat-icon>search</mat-icon>
      <input
        class="form-control search-input"
        type="text"
        placeholder="Search"
        aria-label="Search"
        #search
        [formControl]="searchControl"
      />
    </span>
    </div>
    <div class="add-item">
      <button mat-button class="blue-button" (click)="addInstrument()">
        <mat-icon>add</mat-icon> Add an item
      </button>
    </div>
  </div>
  <div
    class="table-container"
    infiniteScroll
    [infiniteScrollDistance]="1"
    [infiniteScrollThrottle]="300"
    (scrolled)="onScroll()"
    [scrollWindow]="false"
  >
    <div class="loading-shade" *ngIf="loading">
      <mat-spinner *ngIf="loading"></mat-spinner>
    </div>
  <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortInstrumentData($event)"
         class="mat-elevation-z8">
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header >
        Instrument
      </th>
      <td mat-cell *matCellDef="let element" class="w-700"> <img src="assets/images/no-image.svg" class="table-image" alt="instrument"> {{element.name}}</td>
    </ng-container>

    <ng-container matColumnDef="vendor__name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Manufacturer
      </th>
      <td mat-cell *matCellDef="let element"><span class="manufacturer-outline">{{element?.vendor?.name}}</span></td>
    </ng-container>

    <ng-container matColumnDef="sku">
      <th mat-header-cell *matHeaderCellDef mat-sort-header >
        SKU
      </th>
      <td mat-cell *matCellDef="let element">{{element.sku}} </td>
    </ng-container>
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef mat-sort-header >
        Actions
      </th>
      <td mat-cell *matCellDef="let element">

        <button mat-icon-button matTooltip="Edit intrument" color="primary" (click)="openUpdateCatalog(element, 0)">
        <mat-icon aria-label="Edit">edit</mat-icon>
      </button>
        <button mat-icon-button matTooltip="Delete intrument"  color="warn" (click)="deleteCatalogInstrument(element)">
          <mat-icon aria-label="Delete">delete</mat-icon>
        </button> </td>
    </ng-container>
<!--    <ng-container matColumnDef="ljAdmin">-->
<!--      <th mat-header-cell *matHeaderCellDef mat-sort-header >-->
<!--        LJ admin only-->
<!--      </th>-->
<!--      <td mat-cell *matCellDef="let element">-->
<!--        <button mat-icon-button matTooltip=""  color="warn">-->
<!--          <img src="assets/images/lj-admin-only.svg" alt="">-->
<!--        </button> </td>-->
<!--    </ng-container>-->
    <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns1;"></tr>
  </table>
  </div>
</div>
</ng-container>

<ng-container *ngIf="tableValue==='instruments-queue'">
  <div class="table-container">
    <div class="top-toolbar" >
      <div class="left">
        <span class="team-name">
          {{ teamName }} Instruments Queue
        </span>
        <span class="status-container">
         <span class="status-tooltip">Filter by status: </span>
        <span class="status-filter">
        <mat-checkbox
          [color]="'primary'"
          [checked]="true"
          class="filter-checkbox">Awaiting Approval</mat-checkbox>
        <mat-checkbox
          [color]="'primary'"
          class="filter-checkbox">Rejected</mat-checkbox>
        </span>
        </span>

      </div>
      <div class="right">
        <button mat-button class="blue-button" (click)="addInstrument()">
        <mat-icon>add</mat-icon> Add an item
      </button>
      </div>
    </div>
    <div
      class="table-container"
      infiniteScroll
      [infiniteScrollDistance]="1"
      [infiniteScrollThrottle]="300"
      (scrolled)="onScroll()"
      [scrollWindow]="false"
    >
      <div class="loading-shade" *ngIf="loading">
        <mat-spinner *ngIf="loading"></mat-spinner>
      </div>
    <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortInstrumentData($event)"
           class="mat-elevation-z8">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >
          Instrument
        </th>
        <td mat-cell class="w-700" *matCellDef="let element" > <img src="assets/images/no-image.svg" class="table-image" alt="instrument"> {{element.name}}</td>
      </ng-container>
      <ng-container matColumnDef="vendor__name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Manufacturer
        </th>
        <td mat-cell *matCellDef="let element"><span class="manufacturer-outline">{{element?.vendor?.name}}</span></td>
      </ng-container>
      <ng-container matColumnDef="sku">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >
          SKU
        </th>
        <td mat-cell *matCellDef="let element">{{element.sku}} </td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >
          Status
        </th>
        <td mat-cell *matCellDef="let element">{{statusMapping(element.status)}}</td>
      </ng-container>
      <ng-container matColumnDef="visibility">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Visibility
        </th>
        <td mat-cell class="no-padding" *matCellDef="let element">
        <mat-form-field class="no-input-underline">
            <mat-select [value]="element.is_organization_public" (selectionChange)="changeVisibility($event, element)" >
              <mat-option *ngFor="let visibility of visibilities" [value]="visibility.value">
                {{ visibility.viewValue }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </td>
        <td mat-cell *matCellDef="let element">{{element.status}}</td>
      </ng-container>
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >
          Date
        </th>
        <td mat-cell *matCellDef="let element">{{element.date_created |date:'MM-dd-yy'}}</td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >
          Actions
        </th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button matTooltip="Accept intrument" color="primary" (click)="approveInstrument(element)">
            <img src="assets/images/accept-photo.svg" alt="">
          </button>
          <button mat-icon-button matTooltip="Edit intrument" color="primary" (click)="openUpdateCatalog(element, 0)">
            <mat-icon aria-label="Edit">edit</mat-icon>
          </button>
          <button mat-icon-button matTooltip="Delete intrument"  color="warn" (click)="deleteCatalogInstrument(element)">
            <mat-icon aria-label="Delete">delete</mat-icon>
          </button> </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns2;"></tr>
    </table>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="tableValue==='photo-queue'">
  <div class="table-container">
    <div class="top-toolbar-photo">
      <div class="left">
        <span class="team-name">
          {{ teamName }} Photo Queue
        </span>
      </div>
    </div>
    <div
      class="table-container"
      infiniteScroll
      [infiniteScrollDistance]="1"
      [infiniteScrollThrottle]="300"
      (scrolled)="onScroll()"
      [scrollWindow]="false"
    >
      <div class="loading-shade" *ngIf="loading">
        <mat-spinner *ngIf="loading"></mat-spinner>
      </div>
    <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortPhotoData($event)"
           class="mat-elevation-z8">
      <ng-container matColumnDef="photo">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >
          New Photos
        </th>
        <td mat-cell *matCellDef="let element"> <img src="{{element.url}}" class="table-photo" alt="instrument"></td>
      </ng-container>
      <ng-container matColumnDef="organization_medical_instrument__medical_instrument__name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >
          Instrument
        </th>
        <td mat-cell *matCellDef="let element" class="w-600">{{element.medical_instrument.name}}</td>
      </ng-container>
      <ng-container matColumnDef="organization_medical_instrument__medical_instrument__vendor__name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Manufacturer
        </th>
        <td mat-cell *matCellDef="let element"><span class="manufacturer-outline">{{element.medical_instrument.vendor}}</span></td>
      </ng-container>
      <ng-container matColumnDef="organization_medical_instrument__medical_instrument__sku">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          SKU
        </th>
        <td mat-cell *matCellDef="let element">{{element.medical_instrument.sku}} </td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >
          Status
        </th>
        <td mat-cell *matCellDef="let element" [ngClass]="{'accepted-text': element.is_approved}">{{element.is_approved ? 'Approved' : 'Awaiting Approval'}}</td>
      </ng-container>
      <ng-container matColumnDef="date_created">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >
          Date
        </th>
        <td mat-cell *matCellDef="let element">{{element.date_created |date:'MM-dd-yy'}}</td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >
          Actions
        </th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button matTooltip="Accept instrument" color="primary" (click)="acceptInstrument(element)" [ngClass]="{'accepted-icon': element.is_approved}">
            <i [ngClass]="element.is_approved ? 'accepted-icon' : 'not-accepted-icon'"></i>
          </button>
          <button mat-icon-button matTooltip="Delete instrument"  color="warn" (click)="deleteInstrument(element)">
            <img src="assets/images/decline-photo.svg" alt="">
          </button> </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns3"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns3;"></tr>
    </table>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="tableValue==='notes'">
  <div class="table-container">
    <div class="top-toolbar-photo">
      <div class="left">
        <span class="team-name">
          {{ teamName }} note Queue
        </span>
      </div>
    </div>
    <div
      class="table-container"
      infiniteScroll
      [infiniteScrollDistance]="1"
      [infiniteScrollThrottle]="300"
      (scrolled)="onScroll()"
      [scrollWindow]="false"
    >
      <div class="loading-shade" *ngIf="loading">
        <mat-spinner *ngIf="loading"></mat-spinner>
      </div>
      <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortInstrumentData($event)"
             class="mat-elevation-z8">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header >
            Instrument
          </th>
          <td mat-cell class="w-700" *matCellDef="let element" > <img src="assets/images/no-image.svg" class="table-image" alt="instrument"> {{element.name}}</td>
        </ng-container>
        <ng-container matColumnDef="vendor__name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header >
            Manufacturer
          </th>
          <td mat-cell *matCellDef="let element" ><span class="manufacturer-outline">{{element.vendor.name}}</span></td>
        </ng-container>
        <ng-container matColumnDef="sku">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            SKU
          </th>
          <td mat-cell *matCellDef="let element">{{element.sku}}</td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Status
          </th>
          <td mat-cell *matCellDef="let element">{{statusMapping(element.status)}} </td>
        </ng-container>
        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef mat-sort-header >
            Date
          </th>
          <td mat-cell *matCellDef="let element" >{{element.date_created |date:'MM-dd-yy'}}</td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef mat-sort-header >
            Actions
          </th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button matTooltip="Open note editor" color="primary" (click)="openUpdateCatalog(element, 1)">
              <span class="review-note"> <img src="assets/icons/review-note.svg" alt="note"> Review note</span>
            </button>

          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns4"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns4;"></tr>
      </table>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="tableValue==='cv'">
  <div class="table-container">
    <div class="top-toolbar-photo">
      <div class="left">
        <span class="team-name">
          {{ teamName }} CV Queue
        </span>
      </div>
    </div>
    <div
      class="table-container"
      infiniteScroll
      [infiniteScrollDistance]="1"
      [infiniteScrollThrottle]="300"
      (scrolled)="onScroll()"
      [scrollWindow]="false"
    >
      <div class="loading-shade" *ngIf="loading">
        <mat-spinner *ngIf="loading"></mat-spinner>
      </div>
      <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortInstrumentData($event)"
             class="mat-elevation-z8">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header >
            Instrument
          </th>
          <td mat-cell class="w-700" *matCellDef="let element" > <img src="assets/images/no-image.svg" class="table-image" alt="instrument"> {{element?.name}}</td>
        </ng-container>
        <ng-container matColumnDef="vendor__name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header >
            Manufacturer
          </th>
          <td mat-cell *matCellDef="let element" ><span class="manufacturer-outline">{{element.vendor}}</span></td>
        </ng-container>
        <ng-container matColumnDef="sku">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            SKU
          </th>
          <td mat-cell *matCellDef="let element">{{element.sku}}</td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Status
          </th>
          <td mat-cell *matCellDef="let element">{{statusMapping(element.status)}} </td>
        </ng-container>
        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef mat-sort-header >
            Date
          </th>
          <td mat-cell *matCellDef="let element" >{{element.date_created |date:'MM-dd-yy'}}</td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef mat-sort-header >
            Actions
          </th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button matTooltip="Open note editor" color="primary" (click)="openUpdateCatalog(element, 2)">
              <span class="review-note"> <img src="assets/icons/review-note.svg" alt="note">View Captures</span>
            </button>

          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns4"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns4;"></tr>
      </table>
    </div>
  </div>
</ng-container>
