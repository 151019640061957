<div class="container">
  <mat-tab-group [selectedIndex]="data.tabIndex">
    <mat-tab label="Information and catalog Photo">
      <app-information-and-catalog style="height: 100%"
        [instrumentData]="data"
         (updateForm)="onCancelClick()"
      ></app-information-and-catalog></mat-tab>
    <mat-tab label="Notes">
      <app-notes-edit
        [instrumentData]="data"
      ></app-notes-edit>
    </mat-tab>
    <mat-tab label="CV Library">
      <app-cv-library
        [instrumentData]="data"
      ></app-cv-library>
    </mat-tab>
  </mat-tab-group>
</div>
