import { Injectable } from '@angular/core';
import {
  CanActivate,
  Router,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
  NavigationEnd
} from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { OrganizationsService } from '../services/organizations/organizations.service';
import { MemoryStorage } from '../services/memory-storage/memory-storage.service';
import { User } from '../interfaces/user.interface';
import { UsersService } from '../services/users/users.service';

const localStorage = new MemoryStorage('localStorage');

@Injectable()
export class OrganizationUUIDControlGuard implements CanActivate {
  constructor(
    private router: Router,
    private organizationsService: OrganizationsService,
    private usersService: UsersService,
  ) { }
  // private processOrganizationData(profile: User | any): boolean {
  //   const { organization_uuid } = profile;
  //   // const hasOrgUuid = this.organizationsService.getOrganizationUuid() !== '';
  //   const hasOrgList = Array.isArray(profile.organizations) && profile.organizations.length > 0;
  //   const orgUuid = hasOrgList ? profile?.organizations[0]?.uuid : organization_uuid;

  //   let isUUIDPresented = false;

  //   if (orgUuid) {
  //     this.organizationsService.setOrganizationUuid(orgUuid);
  //     isUUIDPresented = true;
  //   }

  //   return isUUIDPresented;
  // }

  canActivate(
    route: ActivatedRouteSnapshot,
    routerState: RouterStateSnapshot
  ): boolean | Observable<boolean> {
    const subject = new Subject<boolean>();

    this.usersService.getProfile().subscribe({
      next: (profile: User) => {
        const isUUIDPresented = !!this.organizationsService.getOrganizationUuid();

        if (!isUUIDPresented) {
          this.router.navigate(['/not-authenticated']);
        }

        subject.next(isUUIDPresented);
      },
      error: (error) => {
        this.router.navigate(['/not-authenticated']);

        subject.next(false);
      },
      complete: () => { },
    });

    return subject.asObservable();
  }
}
