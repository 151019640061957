<div class="header">
  <h2 mat-dialog-title>{{data?.title}}</h2>
  <button mat-icon-button [mat-dialog-close]="false" *ngIf="data.hasClose">
    <mat-icon>close</mat-icon>
  </button>
</div>

<div mat-dialog-content>
  <p [innerHtml]="data?.message"></p>
</div>

<div mat-dialog-actions [align]="'end'">
  <button mat-stroked-button color="primary" [mat-dialog-close]="data" *ngIf="data?.confirmCaption">
    {{data?.confirmCaption}}
  </button>
  <button mat-stroked-button color="warn" [mat-dialog-close]="false" *ngIf="data?.cancelCaption">
    {{data?.cancelCaption}}
  </button>
</div>
