import { TranslationsService } from './../../shared/services/translations/translations.service';
import { DialogService } from './../../shared/services/dialog/dialog.service';
import { ToastrService } from 'ngx-toastr';
import { TrayService } from './../../shared/services/tray/tray.service';
import { MessageService } from 'src/app/shared/services/message/message.service';
import {Component, OnInit, OnDestroy, Inject} from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import {
  filter,
  Subject,
  takeUntil,
  Subscription,
  mergeMap
} from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import {
  NO_HEADER_AND_FOOTER_ROUTES,
  MINOR_SET_ROUTE,
  ACTIVE_SECTION_UUID,
  ACTIVE_TRAY_UUID,
  ORGANIZATION_UUID,
  USER_LANGUAGE,
  USER_ORGANIZATION_LIST,
  SHOW_OWNED_TRAYS
} from 'src/app/shared/constants/general.constants';
import { OrganizationsService } from 'src/app/shared/services/organizations/organizations.service';
import { MinorSetService } from 'src/app/shared/services/minor-set/minor-set.service';
import { MatSelectChange } from '@angular/material/select';
import { Tray } from 'src/app/shared/interfaces/tray.interface';
import { PermissionsService } from './../../shared/services/permissions/permission.service';
import { OnboardingService } from 'src/app/shared/services/onboarding/onboarding.service';
import {environment} from "../../../environments/environment";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  public teams: any;
  // ────────────────────────────────────────────────────────────────────────────────
  // Show sub menu
  private _unsubscribeAll: Subject<any>;
  //public showSubMenu: boolean = false;
  public isMinorSet: boolean = false;
  // ────────────────────────────────────────────────────────────────────────────────
  // Show header
  public showHeader: boolean = false;
  // ────────────────────────────────────────────────────────────────────────────────
  // Loading
  public loading = false;
  darkTheme = false;
  // ─────────────────────────────────────────────────────────────────────
  // Tray notes shown
  public trayNotesShown: boolean = false;
  private trayDataSubscription!: Subscription;

  public trayData: Tray | any = {
    name: ''
  }

  public supportEmail = environment.supportEmail;
  public inviteDialogRef: any;

  constructor(public auth: AuthService, private router: Router,
    public organizationsService: OrganizationsService,
    public minorSetService: MinorSetService,
    private messageService: MessageService,
    private trayService: TrayService,
    private toastr: ToastrService,
    public permissionsService: PermissionsService,
    private customDialog: DialogService,
    public translationsService: TranslationsService,
    private onboardingService: OnboardingService,
    public dialog: MatDialog) {
    this._unsubscribeAll = new Subject();
    this.router.events
      .pipe(
        takeUntil(this._unsubscribeAll),
        filter((event: any): event is NavigationEnd => event instanceof NavigationEnd)
      )
      .subscribe((event: NavigationEnd) => {
        let eventUrl = event.url;
        if(eventUrl) {
          eventUrl = event.url.split('?')[0];
        }
        this.isMinorSet = MINOR_SET_ROUTE.includes(eventUrl);
        this.showHeader = !(NO_HEADER_AND_FOOTER_ROUTES.includes(eventUrl) || NO_HEADER_AND_FOOTER_ROUTES.includes(event.urlAfterRedirects));
      });
    this.teams = this.organizationsService.organizations;
    this.trayDataSubscription = this.minorSetService.$trayData.pipe(
      takeUntil(this._unsubscribeAll),
    ).subscribe({
      next: (trayData: Tray) => {
        this.trayData = trayData;
      }
    });
  }

  ngOnInit() {
    this.auth.isAuthenticated$.pipe(
      mergeMap(async (status) => {
        this.darkTheme = !status;
      })).subscribe();
  }

  ngOnDestroy(): void {
    // ─────────────────────────────────────────────────────────────────
    // *AutoUnsubscribe need ngOnDestroy to be present please do NOT delete
    // ─────────────────────────────────────────────────────────────────
    this._unsubscribeAll.next({});
    this._unsubscribeAll.complete();
  }

  logout(): void {
    this.clearLocalStorage();
    this.auth.logout({ returnTo: window.location.origin });
  }

  preventMenuClosing($event: Event) {
    $event.stopPropagation();
  }

  setActiveOrganization($event: MatSelectChange) {
    this.organizationsService.setOrganizationUuid($event.value);
    this.organizationsService.setOrganizationInviteCode();
    this.permissionsService.updatePermissionForOrganization();
    window.location.reload();
  }

  clearLocalStorage() {
    localStorage.removeItem(ORGANIZATION_UUID);
    localStorage.removeItem(ACTIVE_SECTION_UUID);
    localStorage.removeItem(ACTIVE_TRAY_UUID);
    localStorage.removeItem(USER_ORGANIZATION_LIST);
    localStorage.removeItem(USER_LANGUAGE);
    localStorage.removeItem(SHOW_OWNED_TRAYS);
  }

  // ─────────────────────────────────────────────────────────────────────
  // Header menu actions

  public printTheMat() {
    this.customDialog
      .confirmDialog({
        title: 'Instructions',
        message: `
        <The mat is needed for the beta period.
        <br><br>
        1. Print the mat on a US-Letter-size paper at 100% scale
        <br>
        2. Verify the markers are 15mm x 15mm
        <br>
        3. Do not laminate`,
        confirmCaption: 'Print',
        cancelCaption: 'Cancel',
        dialogData: {
          url: 'https://a419302b-97f4-4493-ac26-380319cca880.usrfiles.com/ugd/a41930_e0175051f8c3435e8833557971b3b352.pdf',
          target: '_blank'
        },
      })
      .subscribe((data) => {
        if (data.dialogData && data.dialogData.url && data.dialogData.target) {
          window.open(data.dialogData.url, data.dialogData.target);
        }
      });
  }

  // ─────────────────────────────────────────────────────────────────────
  // Translation
  public setLanguage($event: MatSelectChange) {
    this.translationsService.setLanguage($event.value)
  }

  // ─────────────────────────────────────────────────────────────────────
  // Onboarding
  public trayOnboarding() {
    this.onboardingService.startTour('trays')
  }

  openNavLink(link: string) {
    if(link.includes('https://')) {
      window.open(link, '_blank');
    } else {
      this.router.navigate([link]);
    }
    return false;
  }

  public openInvite() {
    this.inviteDialogRef = this.dialog.open(InviteDialog, {
      width: '370px',
      data: '',
      autoFocus: false,
    });
    this.inviteDialogRef.afterClosed().subscribe((result: any) => { });
  }
}

// Invite dialog ────────────────────────────────────────────────────────────────────────────────
@Component({
  selector: 'invite',
  templateUrl: 'dialogs/invite.dialog.html',
  styleUrls: ['./header.component.scss'],
})
export class InviteDialog {

  public copyColor = 'primary'

  constructor(
      public organizationsService: OrganizationsService,
      public dialogRef: MatDialogRef<string>,
      @Inject(MAT_DIALOG_DATA) public data: string
  ) { }

  dataCopied() {
    this.copyColor = 'warn'
    setTimeout(() => {
      this.copyColor = 'primary'
    }, 1e3);
  }

  cancel() {
    this.dialogRef.close();
  }
}
